import React, { useEffect, useState } from "react";

import InputKendoRctV1 from "src/control-components/input/inputV1";
import DatePickerKendoRct from "src/control-components/date-picker/date-picker";
import moment from "moment";
import { subtle } from "crypto";

export const NichqHeader = ({
    listItems,
    setListItems,
    title,
    subTitle,
    disabled
}) => {


    const [opList, setOpList] = useState(listItems)

    useEffect(() => {
        if (opList && opList.length > 0) {
            setListItems(opList);
        }
    }, [opList])



    const handleRadioChange = (itemId, value) => {
        // Find the item in the items array

        setOpList(list => list.map((item, i) =>
            item.id === itemId
                ? {
                    ...item,
                    value: value
                }
                : item
        ));
    }


    return (<>

        {opList?.map((item) => (
            <div className={item.type === 'radio' ? "mb-0 col-lg-12 col-md-6 col-12" : "mb-0 col-lg-4 col-md-6 col-12"}>
                {
                    ((item.type === 'question' || item.type === 'date') && disabled) ? <div className="column">

                        <div key={item.name} className="content  valueColor fw-600 mb-0 font-12 labelFonts">
                            {item.name}
                        </div>
                        <div className="client-name">
                            {item.value}
                        </div>

                    </div> :
                        item.type === 'question' ?

                            <InputKendoRctV1
                                value={item.value}
                                onChange={(handleTextChange) => { handleRadioChange(item.id, handleTextChange.value) }}
                                name={item.id}
                                label={item.name}
                                disabled={disabled}
                            />
                            :
                            item.type === 'date' ?
                                <DatePickerKendoRct
                                    disabled={disabled}
                                    value={item.value && new Date(item.value)}
                                    onChange={(handleTextChange) => {
                                        handleRadioChange(item.id,
                                            moment(handleTextChange.target.value).format('M/D/YYYY'))
                                    }}
                                    label={item.name}
                                />
                                : item.type === 'radio' ?
                                    <div key={item.id}>
                                        {item.parentHeader &&
                                            <div className=" row col-12 head-6  mt-3 mb-0">
                                                {item.parentHeader}
                                            </div>
                                        }
                                        <div className="content  valueColor fw-600 mb-0 font-12 labelFonts">
                                            {subTitle}
                                        </div>


                                        <div className="client-name ">
                                            <label>{item.name}</label>
                                        </div>
                                        {item.buttons.map((btn) => (
                                            <label key={btn.value} className="fw-400 cats_label">
                                                <input
                                                    type="radio"
                                                    value={item.value}
                                                    checked={item.value === btn.value}
                                                    onChange={() => handleRadioChange(item.id, btn.value)}
                                                    className="mr-2 mb-0"
                                                />
                                                {btn.label}
                                            </label>
                                        ))}</div> :
                                    <></>
                }

            </div>

        ))}

    </>)
}
