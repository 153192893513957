import ApiHelper from "../helper/api-helper";
import moment from "moment";
import { Encrption } from "../app-modules/encrption";
import { customAxios } from "./useExPressApi";
import { UserAuthResponse } from "src/dataModels/authDataModels";
import { API_ENDPOINTS } from "./api-endpoints";
import APP_ROUTES from "src/helper/app-routes";
import { store } from "src/redux/store/store";
import { changeAppRoute } from "src/redux/actions";

// ------------------------------Update clinic--------------------------------

const updateClinic = (fields, clinicId) => {


  let fullZip = fields?.zip + (fields?.zipOpt ? fields?.zipOpt : "")
  var data = {
    id: clinicId,
    address: fields?.address ? fields?.address : "",
    state: fields?.state ? fields?.state : "",
    city: fields?.city ? fields?.city : "",
    zip: fullZip.trim(),
    phone: fields?.phone ? fields?.phone : "",
    fax: fields?.fax ? fields?.fax : "",
    email: fields?.email ? fields?.email : "",
    websiteUrl: fields?.websiteUrl ? fields?.websiteUrl : "",
    npi: fields?.npi ? fields?.npi : "",
    startOfWeek: fields?.startOfWeek?.id ? fields?.startOfWeek?.id : "",
    taxnomy: fields?.taxnomy ? fields?.taxnomy : "",
    roundUp: fields?.roundUp,
    taxId: fields?.taxId ? fields?.taxId : "",
    openTime:fields?.businessStartHour ? moment(fields?.businessStartHour).format('HH:mm:ss') : "",
    closeTime:fields?.businessEndHour ? moment(fields?.businessEndHour).format('HH:mm:ss') : ""
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_CLINIC, data);
};

//  ----------------------------- Ref Provider ------------------------------

const insertClinicReferring = (fields) => {
  var data = {
    companyName: fields.companyName,
    firstName: fields.firstName,
    lastName: fields.lastName,
    npi: fields.npi,
    email: fields.email,
    contactPhone: fields.mobilePhone,
    address: fields.address,
    city: fields.city,
    state: fields.state,
    zip: fields.zipCode,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_CLINIC_REFERRING_PROVIDER, data);
};

const getClinicReferringProvider = (clinicId, activeType) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_CLINIC_REFERRING +
    Encrption(clinicId) +
    "&isActive=" +
    activeType
  );
};

const deleteClinicRef = (id) => {
  return ApiHelper.deleteRequest(
    API_ENDPOINTS.DELETE_CLINIC_REFERRING + Encrption(id),
    null,
    true
  );
};

const reActivateClinicReferring = (id) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.REACTIVATE_CLINIC_REFERRING + Encrption(id)
  );
};

const getRefProviderById = (id, activeType) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_CLINIC_REF_BY_ID + Encrption(id) + "&isActive=" + activeType
  );
};

const updateClinicReferring = (fields, id) => {
  var data = {
    companyName: fields.companyName,
    firstName: fields.firstName,
    lastName: fields.lastName,
    npi: fields.npi,
    email: fields.email,
    contactPhone: fields.mobilePhone,
    address: fields.address,
    city: fields.city,
    state: fields.state,
    zip: fields.zipCode,
    id: id,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_CLINIC_REFERRING_PROVIDER, data);
};

//  ----------------------------- Ref Source ------------------------------

const insertRefSource = (fields) => {
  var data = {
    referringCompanyName: fields.companyName,
    referringCompanyNPI: fields.npi,
    contactPerson: fields.contactPerson,
    contactPersonPosition: fields.position,
    contactPhone: fields.mobilePhone,
    contactEmail: fields.email,
    contactFax: fields.fax,
    address: fields.address,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_REF_SOURCE, data);
};

const getRefSource = (clinicId, activeType) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_REF_SOURCE + Encrption(clinicId) + "&isActive=" + activeType
  );
};

const getRefSourceById = (id, activeType) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_REF_SOURCE_BY_ID + Encrption(id) + "&isActive=" + activeType
  );
};

const updateRefSource = (fields, id) => {
  var data = {
    referringCompanyName: fields.companyName,
    referringCompanyNPI: fields.npi,
    contactPerson: fields.contactPerson,
    contactPersonPosition: fields.position,
    contactPhone: fields.mobilePhone,
    contactEmail: fields.email,
    contactFax: fields.fax,
    address: fields.address,
    id: id,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_REF_SOURCE, data);
};

const deleteRefSource = (id) => {
  return ApiHelper.deleteRequest(
    API_ENDPOINTS.DELETE_REF_SOURCE + Encrption(id),
    null,
    true
  );
};

const reActivateRefSource = (id) => {
  return ApiHelper.getRequest(API_ENDPOINTS.REACTIVATE_REF_SOURCE + Encrption(id));
};

//  ----------------------------- Client Referral Source ------------------------------

const insertClientRefSource = (fields, id) => {
  var data = {
    clientId: id,
    referralSourceId: fields.refName.id,
    referralReason: fields.refReason,
    dateReferral: moment(fields.refDate).format("YYYY-MM-DD"),
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_CLIENT_REF_SOURCE, data);
};

const getClientRefSource = (id) => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_REF_SOURCE + Encrption(id));
};

const insertClientRefProvider = (fields, id) => {
  var data = {
    clientId: id,
    referralProviderId: fields.refName.id,
    referralReason: fields.refReason,
    dateReferral: moment(fields.refDate).format("YYYY-MM-DD"),
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_CLIENT_REF_PROVIDER, data);
};

const getClientRefProvider = (id) => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_REF_PROVIDER + Encrption(id));
};

const uploadClinicLogo = (profile, clinicId) => {
  let bodyFormData = new FormData();
  bodyFormData.append("logoFile", profile, profile.path);
  bodyFormData.append("clinicId", clinicId);
  return ApiHelper.putRequest(API_ENDPOINTS.UPLOAD_CLINIC_LOGO, bodyFormData);
};



const updateDocumentSettings = (fields) => {

  var data = {
    numDaysAllowedInPast: fields.pastAllowedDays ? fields.pastAllowedDays : 0,
    numDaysApplySigAfterDos: fields.daysAppliedSigDos
      ? fields.daysAppliedSigDos
      : 0,
    numDaysApplySigAfterDateLocked: fields.daysAppliedSigLocked
      ? fields.daysAppliedSigLocked
      : 0,
    // allowDocWithoutDiag: fields.allowWithoutDiag,
    canApplySigOnSubmission: fields.signOnSubmission,
    allowDocumentsWithoutAuth: fields.allowDocumentsWithoutAuth === true ? 1 : 0,
    // allowStaffToDuplicateThierOwnDocs:
    //   fields.allowStaffToDuplicateThierOwnDocs === true ? 1 : 0,
    canSubmitAndBillWithNoEligibility:
      fields.canSubmitAndBillWithNoEligibility, 
    canChooseSigDate: fields.canChooseSigDate ? true : false,
    allowBookendingSrvTimes: fields.allowBookendingSrvTimes ? true : false,
    showDocumentHistoryOnPrint: !!fields.showDocumentHistoryOnPrint,
    canUseGoalAfterTargetDate: fields.canUseGoalAfterTargetDate,
    canSubmitDocAfterBusinessHours: fields.canSubmitDocAfterBusinessHours
    // "canSealDocument":fields.canSealDocument
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_DOCUMENT_SETTINGS, data);
};

const getDocSettings = (clinicId) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_DOCUMENT_SETTINGS + Encrption(clinicId)
  );
};

const updateCertificates = (fields) => {
  var data = {
    id: fields.id,
    certificationName: fields.certificateName,
    isRestrictive: fields.isRestrictive,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_CERTIFICATES, data);
};

const addCertificates = (fields, clinicId) => {
  var data = {
    clinicId: clinicId,
    certificationName: fields.certificateName,
    isRestrictive: fields.isRestrictive,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_CERTIFICATES, data);
};

const deleteCertificate = (id) => {
  return ApiHelper.deleteRequest(
    API_ENDPOINTS.DELETE_CERTIFICATE + Encrption(id),
    null,
    true
  );
};

const addCliniClientFlag = (fields, flagColor) => {
  var data = {
    color: flagColor,
    flagName: fields.flagName,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_CLIENT_FLAG, data);
};

const getClinicFlags = () => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_CLINIC_CLIENT_FLAGS 
  );
};

const deleteFlag = (id) => {
  return ApiHelper.deleteRequest(
    API_ENDPOINTS.DELETE_FLAG + Encrption(id),
    null,
    true
  );
};

const getClinicFlagById = (id) => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_FLAG_BY_ID + Encrption(id));
};

const updateCliniClientFlag = (fields, flagColor) => {
  var data = {
    id: fields.id,
    color: flagColor,
    flagName: fields.flagName,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_CLIENT_FLAG, data);
};

const addCliniClientTags = (fields, tagColor) => {
  var data = {
    color: tagColor,
    tagName: fields.tagName,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.INSERT_CLINIC_TAG, data);
};

const getClinicTags = (clinicId) => {
  return ApiHelper.getRequest(
    API_ENDPOINTS.GET_CLINIC_CLIENT_TAGS + Encrption(clinicId) + "&isActive=" + true
  );
};

const deleteTags = (id) => {
  return ApiHelper.deleteRequest(
    API_ENDPOINTS.DELETE_TAG + Encrption(id),
    null,
    true
  );
};

const deleteClientStatus = (id) => {
  return ApiHelper.deleteRequest(API_ENDPOINTS.DELETE_CLIENT_STATUS + Encrption(id));
};

const getClinicTagsById = (id) => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_TAG_BY_ID + Encrption(id));
};
const getclientStatus = () => {
  return ApiHelper.getRequest(API_ENDPOINTS.GET_CLIENT_STATUS);
};

const updateCliniClientTags = (fields, tagColor) => {
  var data = {
    id: fields.id,
    color: tagColor,
    tagName: fields.tagName,
  };
  return ApiHelper.putRequest(API_ENDPOINTS.UPDATE_CLINIC_TAG, data);
};

const addClientStatus = (fields, clinicId) => {
  var data = {
    statusName: fields.statusName,
    clinicId: clinicId,
  };
  return ApiHelper.postRequest(API_ENDPOINTS.ADD_CLIENT_STATUS, data);
};

const editClientStatus = (fields) => {
  var data = {
    statusName: fields.statusName,
    id: fields.id,
  };
  return customAxios.put(API_ENDPOINTS.EDIT_CLIENT_STATUS, data);
};

const getClientStatusById = (id) => {
  return customAxios.get(API_ENDPOINTS.GET_CLIENT_STATUS_BY_ID+id,);
};

const getClinicDetails = (id) => {
  let ClinicId = (!id || id===null)? localStorage.getItem("clinicId") : id;
  if(!ClinicId ||ClinicId===null ){
     store.dispatch(changeAppRoute(APP_ROUTES.DASHBOARD));
    return
  }
  return ApiHelper.getRequest(API_ENDPOINTS.GET_CLINIC + Encrption(ClinicId));
};

const saveClientCompliance = (fields: any) => {
  const data = {
    name: fields?.name,
    description: fields?.description,
    startDate: moment(fields?.startDate).format("YYYY-MM-DD"),
    useClientStartDate: fields?.useClientStartDate,
    daysAfterStartDate: fields?.daysAfterStartDate,
    isRestrictive: fields?.isRestrictive?.value,
    documentTemplateId: fields?.documentTemplateId?.id,
    contingentUponComplianceId: fields?.contingentUponComplianceId?.clientComplianceId,
    serviceCode: fields?.serviceId?.id,
    adultsOnly: fields?.adultsOnly?.value,
    numDaysAfterComplianceReset: fields?.numDaysAfterComplianceReset,
    isRecurring: fields?.isRecurring,
    siteId: fields?.siteId?.id,
    payerIds: fields?.payerIds?.map((payer: any) => payer.id)
  }

  return ApiHelper.putRequest(API_ENDPOINTS.CREATE_CLIENT_COMPLIANCE, data)
}
const updateClientCompliance = (fields: any, selectedId: number) => {
  const data = {
    id: selectedId,
    name: fields?.name,
    description: fields?.description,
    startDate: moment(fields?.startDate).format("YYYY-MM-DD"),
    useClientStartDate: fields?.useClientStartDate,
    daysAfterStartDate: fields?.daysAfterStartDate,
    isRestrictive: fields?.isRestrictive?.value,
    documentTemplateId: fields?.documentTemplateId?.id,
    contingentUponComplianceId: fields?.contingentUponComplianceId?.clientComplianceId,
    serviceCode: fields?.serviceId?.id,
    adultsOnly: fields?.adultsOnly?.value,
    numDaysAfterComplianceReset: fields?.numDaysAfterComplianceReset,
    isRecurring: fields?.isRecurring,
    siteId: fields?.siteId?.id,
    payerIds: fields?.payerIds?.map((payer: any) => payer.id)
  }
  return ApiHelper.postRequest(API_ENDPOINTS.UPDATE_CLIENT_COMPLIANCE, data)
}

const deleteCompliance = (id: any) => {
  return ApiHelper.deleteRequest(API_ENDPOINTS.DELETE_CLIENT_COMPLIANCE + Encrption(id))
}

export const SettingsService = {
  insertClinicReferring,
  getClinicReferringProvider,
  deleteClinicRef,
  reActivateClinicReferring,
  getRefProviderById,
  updateClinicReferring,
  insertRefSource,
  getRefSource,
  getRefSourceById,
  updateRefSource,
  deleteRefSource,
  reActivateRefSource,
  insertClientRefSource,
  getClientRefSource,
  insertClientRefProvider,
  getClientRefProvider,
  uploadClinicLogo,
  updateDocumentSettings,
  getDocSettings,
  updateCertificates,
  deleteCertificate,
  addCertificates,
  addCliniClientFlag,
  getClinicFlags,
  deleteFlag,
  getClinicFlagById,
  updateCliniClientFlag,
  addCliniClientTags,
  getClinicTags,
  deleteTags,
  getClinicTagsById,
  updateCliniClientTags,
  getclientStatus,
  deleteClientStatus,
  addClientStatus,
  editClientStatus,
  getClientStatusById,
  updateClinic,
  getClinicDetails,
  saveClientCompliance,
  updateClientCompliance,
  deleteCompliance,
};


export const getSettings = {
  getClinicLogo: (clinicId: string, inBase64: boolean) => {
    return customAxios.get<any, UserAuthResponse>(`${API_ENDPOINTS.GET_CLINIC_LOGO}${Encrption(clinicId)}&inBase64=${inBase64}`);
  },
}

