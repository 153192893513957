export const API_ENDPOINTS = {
  LOGIN: "/Auth/Login",
  RERESH_TOKEN: "/Auth/RefreshToken",

  PUPPETEER_BASE_URL: "https://puppeteer.notenetic.com:3001",
  // PUPPETEER_BASE_URL: "http://localhost:3001",


  // ------------- TeleHealth---------------

  GET_TELEHEALTH_ACCESS_TOKEN: "/Telehealth/GetTeleheathAccessToken",
  GET_TELEHEALTH_ACCESS_LINK_FOR_CLIENT: "/Telehealth/GetTeleheathAccessLinkForClient",

  //-------------- Announcements --------------
  GET_ADMIN_ANNOUNCEMETS: '/Announcement/GetAnnouncements?showAll=',
  READ_ANNOUNCEMETS: '/Announcement/ReadAnnouncement?id=',

  // -------------Provider---------------
  CREATE_PROVIDER: "/Provider/CreateProvider",
  UPDATE_PROVIDER: "/Provider/UpdateProvider",
  GET_PROVIDER_BY_ID: "/Provider/GetProviderById/",
  GET_PROVIDER_LIST: "/Provider/GetProvderList",
  DELETE_PROVIDER: "/Provider/DeleteProvider",
  UPDATE_CLINIC_DOCUMENET: "/Clinic/UpdateClinicStoredDocument",

  // ------------- Document ---------------
  DOCUMENT_STATUS: "/Common/GetDocumentStatus",
  INSERT_DOCUMENT: "/Document/InsertDocument",
  AUTO_SAVE_DOCUMENT_DRAFT: "/Document/AutoSaveDocumentDraft",
  UPDATE_DOCUMENT: "/Document/UpdateDocument",
  DELETE_DOCUMENT: "/Document/TrashDocument?documentId=",
  UNTRASH_DOCUMENT: "/Document/UnTrashDocument?documentId=",
  DELETE_DOCUMENT_ATTACHMENT: "/Document/DeleteDocumentAttachment?id=",
  DELETE_LINKED_DOCUMENT: "/Document/DeleteLinkedDocumentById?id=",
  GET_INCOMPLETE_COMPLIANCE: "/Document/GetIncompleteComplianceForDocument",
  SET_DOCUMENT_PRINT: "/Document/SetDocumentPrint",
  DELETE_LINKED_QUESTIONNAIRE: "/Document/DeleteClientDocQuestionnaireById?id=",

  DOCUMENT_HISTORY: "/Document/GetDocumentHistory?documentId=",
  GET_ALL_DOCUMENT: "/Document/GetAllDocument",
  DELETE_CLINIC_ATTACHMENT: "/Clinic/DeleteClinicAttachment",
  GET_DOCUMENTS_PAGING: "/Document/GetDocumentsPaging",
  GET_CLIENT_DOCUMENTS_PAGING: "/Document/GetClientDocumentsPaging",
  GET_CLIENT_NOTES_PAGING: "/Document/GetClientNotesPaging",
  GET_DOCUMENTS_TO_BE_BILLED: "/Billing/GetDocumentsToBeBilled",
  INSERT_BILL_TRANSQUEUE: "/Billing/InsertBillingTransQueue",

  FETCH_837_BILL_TRANSQUEUE: "/Billing/Get837BillingTransQueue",
  FETCH_837_BILLING_CLAIM_BY_TRANS_ID: "/Billing/Get837BillingClaimsByTransId?transId=",
  FETCH_837_BILLING_TRANS_BY_CLAIM_ID: "/Billing/Get837BillingTransItemsByClaimId?claimId=",

  FETCH_835_BILL_REMITTANCE: "/Billing/Get835BillingRemittance",
  FETCH_835_BILLING_CLAIM_BY_TRANS_ID: "/Billing/Get835BillingRemittanceClaims?transId=",
  FETCH_835_BILL_REMITTANCE_SERVICE_LINE: "/Billing/Get835BillingRemittanceServiceLines?claimId=",
  FETCH_CLAIM_DETAILS_BY_CLAIM_ID: "/Billing/GetClaimInformation?claimId=",

  INSERT_UPDATE_BILLING_PROFILE: "/Billing/InsertOrUpdateBillingProfile",
  GET_BILLING_PROFILE: "/Billing/GetBillingProfiles",
  GET_BILLING_PROFILE_BY_ID: "/Billing/GetBillingProfilesById?id=",
  DELETE_BILLING_PROFILE: "/Billing/DeleteBillingProfile?id=",


  GET_DOCUMENT_BY_ID: "/Document/GetDocumentById?documentId=", // {documentId}
  GET_DOCUMENT_SERVICES: "/Document/GetDocumentServices?clientId=",
  GET_CLIENT_PROGRESS: "/Common/GetClientProgress",
  GET_DOCUMENT_STAFF_SERVICES: "/Staff/GetStaffServicesDDL?staffId=",
  GET_DOCUMENT_BILLING_HISTORY: "/Document/GetDocumentBillingInfo?documentId=",

  GET_PLACE_OF_SERVICES_DDL_BY_CLINIC_ID:
    "/Document/GetPlaceOfServicesDDLByClinicId?serviceId=",
  GET_DOCUMENT_PREFS_BY_CLINIC_ID:
    "/Document/GetDocumentPrefsByClinicId?clinicId=",
  UPDATE_DOCUMENT_SETTINGS: "/Document/UpdateDocumentPrefs",
  GET_DOCUMENT_SETTINGS: "/Document/GetDocumentPrefsByClinicId?clinicId=",
  GET_DOCUMENT_OVER_LAPPING: "/Document/GetDocumentOverLapping",
  UPLOAD_DOCUMENT_ATTACHMENT: "/Document/UploadDocumentAttachment",
  GET_DOCUMENT_DRAFT: "/Document/GetDocumentDraft",
  GET_DOCUMENT_DRAFT_COUNT: "/Document/GetDocumentDraftCount",
  GET_DOCUMENT_DRAFT_BY_ID: "/Document/GetDocumentDraftById?id=",
  DELETE_DOCUMENT_DRAFT_BY_ID:
    "/Document/DeleteDocumentDraftById?documentDraftId=",
  GET_LATEST_DOCUMENT_BY_TEMPLATE_ID: "/Document/GetLatestDocumentByTemplateId",

  // ------------- Document Template Drafts ---------------
  AUTO_SAVE_TEMPLATE_DRAFT: "/DocumentTemplates/AutoSaveTemplateDraft",
  GET_TEMPLATE_DRAFT: "/DocumentTemplates/GetTemplateDraft",
  DELETE_TEMPLATE_DRAFT_BY_ID:
    "/DocumentTemplates/DeleteTemplateDraftById?templateDraftId=",
  GET_TEMPLATE_DRAFT_BY_ID: "/DocumentTemplates/GetTemplateDraftById?id=",

  // ------------- Document Templates ---------------
  INSERT_DOCUMENT_TEMPLATE: "/DocumentTemplates/InsertDocumentTemplate",
  UPDATE_DOCUMENT_TEMPLATE: "/DocumentTemplates/UpdateDocumentTemplate",
  DELETE_DOCUMENT_TEMPLATE:
    "/DocumentTemplates/DeleteDocumentTemplate?documentTemplateId=",
  GET_DOCUMENT_TEMPLATE_BY_ID: "/DocumentTemplates/GetDocumentTemplateById?documentTemplateId=",
  GET_DOCUMENT_TEMPLATE_BY_CLINIC_ID: "/DocumentTemplates/GetDocumentTemplateByClinicId",
  INSERT_DOCUMENT_TEMPLATE_PREF:
    "/DocumentTemplates/InsertDocumentTemplatePref",
  GET_DOCUMENT_TEMPLATE_PREF_BY_TEMPLATE_ID:
    "/DocumentTemplates/GetDocumentTemplatePrefByTemplateId?templateId=",
  GET_DOCUMENT_TEMPLATE_DRAFT_COUNT: "/DocumentTemplates/GetDocumentDraftCount",
  GET_DOCUMENT_TEMPLATE_TYPES: "/Common/GetDocumentTemplateTypes",

  GET_STAFF_TEMPLATES: "/Staff/GetStaffTemplates?staffId=",

  GET_STAFF_ASSIGNED_TEMPLATES_ONLY: "/Staff/GetStaffAssignedTemplatesOnly?staffId=",


  GET_STAFF_DOCUMENT_SIGNATURE: "/Signature/GetStaffDocumentSignature?documentId=",
  GET_CLIENT_DOCUMENT_SIGNATURE: "/Signature/GetClientDocumentSignature?documentId=",

  GET_DOCUMENT_ATTACHMENT: "/Document/GetDocumentAttachment?documentId=",
  GET_DOCUMENT_TASKS: "/Document/GetDocumentTasks?documentId=",
  GET_BILLING_STATUSES: "/Common/GetBillingStatuses",
  UPDATE_BILLING_STATUSES: "/Document/UpdateDocumentBillingStatus",
  UPDATE_PAYROLL_STATUSES: "/Document/UpdateDocumentPayroll",
  UPDATE_DOCUMENT_TEMPLATE_NAME:
    "/DocumentTemplates/UpdateDocumentTemplateName?templateName=",

  // -------------Provider Location---------------
  ADD_PROVIDER_LOCATION: "/Site/CreateSite",
  GET_PROVIDER_LOCATION: "/Location/GetLocation?providerId=",
  DELETE_PROVIDER_LOCATION: "/Location/DeleteLocation",
  GET_LOCATION_BY_ID: "/Location/GetLocationById/",
  UPDATE_PROVIDER_LOCATION: "/Location/UpdateLocation",

  DELETE_SITE: "/Site/DeleteSite?id=",
  UPDATE_SITE: "/Site/UpdateSite",
  GET_SITE_BY_ID: "/Site/GetSiteById?id=",

  // -------------Staff---------------
  INSERT_STAFF: "/Staff/InsertStaff",
  GET_STAFF_LIST: "/Staff/GetStaff",
  GET_CASELOAD_REPORT_BY_STAFFID: "/staff/GetCaseloadReportByStaffId",
  GET_AVAILABLE_STAFF: "/Staff/GetStaff?isActive=true",
  GET_STAFF_DDL_BY_CLINIC_ID: "/Staff/GetStaffDDLByClinicId?isActive=",
  DELETE_STAFF: "/Staff/DeleteStaff?id=",
  GET_STAFF_BY_ID: "/Staff/GetStaffById?staffId=",
  UPDATE_STAFF: "/Staff/UpdateStaff",
  GET_ROLE: "/Staff/GetRole",
  GET_LOGIN_STAFF: "/Staff/GetLoginStaff",
  GET_APP_VERSION: "/WebHook/GetVersionNumber",
  CREATE_STAFF_SIGNATURE: "/Staff/CreateStaffSignature",
  GET_STAFF_SIGNATURE: "/Staff/GetStaffSignature?staffId=",
  GET_USERNAME_AVAILABLE: "/Staff/IsUserNameAvailable?userName=",
  ACTIVE_STAFF: "/Staff/ActiveStaff?staffId=",
  UPLOAD_STAFF_PROFILE: "/Staff/UploadStaffProfile",
  GET_STAFF_PROFILE: "/Staff/GetStaffProfileImage?staffId=",
  GET_STAFF_BY_ROLE_IDS: "/Staff/getStaffByRoleIds",
  GET_TIMEZONE: "/Common/GetTimeZones",
  POST_STAFF_STORED_DOCUMENTS: "/Staff/InsertStaffStoredDocument",
  GET_STAFF_CASELOAD: "/Staff/GetStaffCaseload",
  POST_STAFF_CASELOAD: "/Staff/AssignClientsToStaffCaseload",
  GET_STAFF_SITES: "/Staff/GetStaffSites",
  POST_STAFF_SITES: "/Staff/AssignSitesToStaff",
  GET_STAFF_TRACK_TIME: "/Staff/GetStaffTrackTime",
  INSERT_STAFF_TRACK_TIME: "/Staff/InsertCustomStaffTrackTime",

  GET_FORMATTED_DATE: "/Common/GetFormattedDate?",

  ASSIGN_SERVICES_TO_STAFF: "/Staff/AssignServicesToStaff",
  GET_STAFF_ASSIGNED_SERVICES: "/Staff/GetStaffServices?staffId=",
  GET_STAFF_EXPIRING_CERTIFICATES:
    "/Staff/GetStaffExpiringCertificates?staffId=",

  ASSIGN_TAG_TO_STAFF_DOC: "/Staff/AssignTagToStaffDocument",

  REMOVE_STAFF_DOC_TAG: "/Staff/DeleteStaffDocumentTag?id=",
  GET_STAFF_TEMPLATES_WITH_ID: "/Staff/GetStaffTemplates?staffId=",//NOTE_need to change in use[GET_STAFF_TEMPLATES]
  ASSIGN_TEMPLATE_TO_STAFF: "/Staff/AssignTemplateToStaff",
  GET_DOCUMENT_TEMPLATES_DDL: "/DocumentTemplates/GetDocumentTemplatesDDL",
  GET_STAFF_TEAM: "/Staff/GetStaffTeam",
  GET_STAFF_BILLING_PROFILE: "/Staff/GetStaffBillingProfileByStaffId?staffId=",
  INSERT_STAFF_BILLING_PROFILE: "/Staff/InsertStaffBillingProfile",
  GET_ACTIVE_STAFF_SEATS: "/Staff/GetActiveStaffSeats",
  // ------------------------Staff setting----------------------

  GET_STAFF_SETTING: "/Staff/GetStaffSettings?staffId=",
  ADD_STAFF_SETTING: "/Staff/AddStaffSettings",
  GET_STAFF_EMAIL_SETTINGS: "/Staff/GetStaffEmailSettings?staffId=",
  ADD_STAFF_EMAIL_SETTINGS: "/Staff/AddStaffEmailSettings",
  GET_STAFF_SMS_SETTINGS: "/Staff/GetStaffSMSSettings?staffId=",
  ADD_STAFF_SMS_SETTINGS: "/Staff/AddStaffSMSSettings",

  // -------------Insurance---------------
  INSERT_INSURANCE: "/Insurance/InsertClientInsurance",
  GET_INSURANCE_LIST: "/Insurance/GetAllClientInsurance",
  DELETE_INSURANCE: "/Insurance/DeleteClientInsurance?id=",
  GET_INSURANCE_BY_ID: "/Insurance/GetClientInsuranceById?id=",
  UPDATE_INSURANCE: "/Insurance/UpdateClientInsurance",
  // GET_INSURANCE_TYPE: "/Insurance/GetInsuranceType?clinicId=",
  GET_INSURANCE_TYPE: "/Insurance/GetInsuranceType?eligibilityOnly=false",
  GET_CLIENT_CURRENT_INSURANCE: "/Insurance/GetClientCurrentInsurance?clientId=",

  // -------------Staff Service Report---------------
  GET_STAFF_SERVICE_REPORT: "/Document/GetStaffServiceReport",


  // -------------Guardian---------------
  INSERT_CLIENT_GUARDIANS: "/Client/InsertClientGuardians",
  GET_CLIENT_GUARDIANS_BY_CLIENT_ID: "/Client/GetClientGuardiansByClientId?clientId=",
  DELETE_CLIENT_GUARDIANS: "/Client/DeleteClientGuardians?id=",
  UPDATE_CLIENT_GUARDIANS: "/Client/UpdateClientGuardians",
  GET_CLIENT_GETGUARDIANS_BY_ID: "/client/GetClientGuardiansById?id=",

  // -------------Physician---------------
  INSERT_CLIENT_PHYSICIAN: "/Client/CreateClientPhysician",
  GET_CLIENT_PHYSICIAN_BY_CLIENT_ID:
    "/Client/GetClientPhysicianByClientId?clientId=",
  DELETE_CLIENT_PHYSICIAN: "/Client/DeleteClientPhysician?id=",
  UPDATE_CLIENT_PHYSICIAN: "/Client/UpdateClientPhysician",
  GET_CLIENT_GETPHYSICIAN_BY_ID: "/client/GetClientPhysicianById?id=",

  // ----------------------------------staff Stored Documnet------------------------------------
  POST_STAFF_STORED_DOCUMNET: "/Staff/InsertStaffStoredDocument",
  GET_STAFF_STORED_DOCUMNET_BY_STAFFID:
    "/Staff/GetStaffStoredDocumentByStaffId?staffId=",
  GET_STAFF_STORED_DOCUMENT_BY_ID: "/Staff/GetStaffDocumentById",
  POST_ADD_ATTACHMENT_DOCUMNET: "/Staff/AddAttachmentToDocument",
  DELETE_STAFF_DOCUMENT: "/Staff/DeleteStaffDocument",
  DELETE_STAFF_ATTACHMENT: "/Staff/DeleteStaffAttachment",
  UPDATE_STAFF_DOCUMENET: "/Staff/UpdateStaffDocument",

  // -------------Client---------------
  ASSIGN_STAFF_TO_CLIENT: "/Client/AssignStaffsToClient",
  GET_CLIENT_ASSIGNED_STAFF: "/Client/GetClientAssignedStaff",
  ASSIGN_SERVICES_TO_CLIENT: "/Client/AssignServicesToClient",
  GET_CLIENT_ASSIGNED_SERVICES: "/Client/GetClientServices",
  INSERT_CLIENT: "/Client/CreateClient",
  GET_CLIENT_BY_CLINIC_ID: "/Client/GetClientByClinicId",
  DELETE_CLIENT: "/Client/DeleteClient?id=",
  UPDATE_CLIENT: "/Client/UpdateClient",
  GET_CLIENT_BY_ID: "/Client/GetClientById?id=",

  GET_CLIENT_ELIGIBILITY_BY_CLIENT_ID:
    "/Client/GetClientAllEligibilityChecks?clientId=",
  GET_CLIENT_ELIGIBILITY_CHECK_BY_ID:
    "/Client/GetClientEligibilityCheckById?eligCheckId=",
  RE_TRY__ELIGIBILITY_CHECK:
    "/Eligibility/ReTryEligibilityCheck?availityId=",


  ASSIGN_SITE_TO_CLIENT: "/Client/AssignSiteToClient",
  UPLOAD_CLIENT_PROFILE: "/Client/UploadClientProfile",
  GET_CLIENT_PROFILE: "/Client/GetClientProfileImage?clientId=",
  GET_CLIENT_DDL_BY_CLINIC_ID: "/Client/GetClientDDLByClinicId?isActive=",
  GET_CLIENT_DDL_BY_SITE_ID: "/Client/GetClientsDDLBySiteId?",

  GET_CLIENTS_WITHOUT_CASELOAD:
    "/Client/GetAllClientsWithOutCaseload?clinicId=",
  GET_CLIENT_STATUS: "/Clinic/GetClientStatusByClinicId",

  CREATE_CLIENT_SIGNATURE: "/Client/InsertClientSignature",
  GET_CLIENT_SIGNATURE: "/Client/GetClientSignature?clientId=",
  GET_CLIENT_VITAL: "/Client/GetClientVitals?clientId=",
  GET_CLIENT_SITES_BY_CLIENTID: "/Client/GetClientSites?clientId=",
  INSERT_CLIENT_SIBLING: "/Client/InsertClientSibling",
  GET_CLIENT_SIBLING_BY_CLIENT_ID:
    "/Client/GetClientSiblingByClientId?clientId=",
  DELETE_CLIENT_SIBLING: "/Client/DeleteClientSibling?id=",
  GET_CLIENT_SIBLING_BY_ID: "/Client/GetClientSiblingById?id=",
  UPDATE_CLIENT_SIBLING: "/Client/UpdateClientSibling",
  REACTIVATE_CLIENT: "/Client/ReactivateClient",
  GENERATE_NEW_SIGNATURE_PIN: "/Client/GenerateNewSignaturePin?clientId=",

  GET_CLIENT_DIAGNOSIS: "/Client/GetClientDiagnosis?clientID=",
  GET_CLIENTS_DIAGNOSIS: "/Client/GetClientsDiagnosis",
  POST_SEAL_DOCUMENT: "/Document/SealDocument",
  POST_DOCUMENT_RATING: "/Document/UpdateDocumentRating",
  LOCK_UNLOCK_DOCUMENTS: "/Document/LockUnlockDocuments",
  APPROVE_DISAPPROVE_DOCUMENTS: "/Document/ApproveOrDisapproveDocuments",

  POST_CLIENT_DIAGNOSIS: "/Client/InsertClientDiagnosis",
  DELETE_CLIENT_DIAGNOSIS: "/Client/DeleteClientDiagnosis?id=",
  UPDATE_CLIENT_DIAGNOSIS: "/Client/UpdateClientDiagnosis",
  REORDER_CLIENT_DIAGNOSIS: "/Client/ReorderClientDiagnosis",
  DELETE_VITAL: "/Client/DeleteClientVitals?id=",
  Add_VITAL: "/Client/InsertClientVitals",
  Add_TREATMENT_PLAN: "/Client/InsertTreatmentPlan",
  Add_TREATMENT_GOAL: "/Client/InsertGoal",
  UPDATE_TREATMENT_GOAL: "/Client/UpdateGoal",

  GET_CLIENT_FLAGS_BY_CLIENT_ID: "/Client/GetClientFlagByClientId?clientID=",
  ASSIGN_FLAG_TO_CLIENT: "/Client/AssignFlagToClient",
  REMOVE_CLIENT_FLAG: "/Client/DeleteClientFlag?id=",
  INSERT_ELIGIBILITY: "/Eligibility/CheckEligibility",

  ASSIGN_TAG_TO_CLIENT_DOC: "/Client/AssignTagToClientDocument",

  REMOVE_CLIENT_DOC_TAG: "/Client/DeleteClientDocumentTag?id=",

  GET_CLIENT_TREATMENT_PLAN_BY_CLIENT_ID:
    "/Client/GetTreatmentPlanByClientId?clientId=",
  GET_CLIENT_TREATMENT_GOAL_BY_PLAN_ID:
    "/Client/GetGoalByTreatmentPlanId?treatmentPlanId=",
  GET_CLIENT_DIAGNOSIS_BY_ID: "/Client/GetClientDiagnosisById",
  GET_CLIENT_INSURANCE_LIST: "/Insurance/GetAllClientInsurance?clientId=",
  GET_CLIENT_INSURANCE_TYPE: "/Insurance/GetInsuranceType",
  POST_CLIENT_INSURANCE: "/Insurance/InsertClientInsurance",
  UPDATE_CLIENT_INSURANCE: "/Insurance/UpdateClientInsurance",
  DELETE_CLIENT_INSURANCE: "/Insurance/DeleteClientInsurance",
  GET_CLIENT_INSURANCE_BY_ID: "/Insurance/GetClientInsuranceById?id=",
  GET_VITAL_BY_ID: "/Client/GetClientVitalsById?id=",
  UPDATE_Vital: "/Client/UpdateClientVitals/",
  ADD_OBJECTIVE: "/Client/InsertObjective",
  GET_PLAN_OBJECTIVE_BY_TREATMENT_PLAN_ID:
    "/Client/GetObjectiveByTreatmentPlanId?treatmentPlanId=",
  GET_INTERVENTION_BY_OBJECTIVE_ID:
    "/Client/GetInterventionByObjectiveId?objectiveId=",
  ADD_INTERVENTION: "/Client/InsertIntervention",
  GET_CLIENT_PLANS_BY_ID: "/Client/GetTreatmentPlanById?id=",
  UPDATE_TREATMENT_PLAN: "/Client/UpdateTreatmentPlan",
  GET_OBJECTIVE_BY_ID: "/Client/GetObjectiveById?id=",
  GET_OBJECTIVE_BY_GOAL_ID: "/Client/GetObjectiveByGoalId?goalId=",
  GET_DOCUMENTS_BY_CLIENT_ID: "/Document/GetDocumentDDLByClientId?clientId=",
  GET_DOCUMENTS_BY_DOCUMENT_ID: "/Document/GetLinkedDocumentByDocId?docId=",
  UPDATE_PLAN_OBJECTIVE: "/Client/UpdateObjective",
  GET_INTERVENTION_BY_ID: "/Client/GetInterventionById?id=",
  LINKED_DOCUMENTS: "/Document/LinkDocument",
  MARK_AS_REVIEWED: "/Document/MarkDocumentReviewed?documentId=",

  GET_QUESTIONNAIRE_BY_CLIENT_ID:
    "/Client/GetClientQuestionnaireByClientId?clientId=",
  LINKED_QUESTOINNAIRE: "/Document/LinkClientQuestionnaire",
  GET_QUESTIONNAIRE_BY_DOCUMENT_ID:
    "/Document/GetClientQuestionnaireByDocId?docId=",

  UPDATE_INTERVENTION: "/Client/UpdateIntervention",
  INSERT_CLIENT_MEDICATION_ADMIN: "/ClientMedication/InsertClientMedicationAdministrations",
  UPDATE_CLIENT_MEDICATION_ADMIN: "/ClientMedication/UpdateClientMedicationAdministrations",

  GET_CLIENT_MEDICATION_ADMIN_BY_CLIENT_ID: "/ClientMedication/GetClientMedicationAdministrationsByClientId?clientId=",
  DELETE_CLIENT_MEDICATION_ADMIN: "/ClientMedication/DeleteClientMedicationAdministrations?id=",

  GET_CLIENT_MEDICATION_ADMIN_BY_ID: "/ClientMedication/GetClientMedicationAdministrationsById?id=",

  INSERT_CLIENT_MED_NOTES: "/ClientMedication/InsertClientMedicationNotes",
  GET_CLIENT_MED_NOTES: "/ClientMedication/GetMedNotesByClientId?clientId=",


  DELETE_TREATMENT_PLAN: "/Client/DeleteTreatmentPlan?id=",
  DELETE_TREATMENT_GOAL: "/Client/DeleteGoal?id=",
  DELETE_TREATMENT_OBJECTIVE: "/Client/DeleteObjective?id=",
  DELETE_TREATMENT_INTERVENTION: "/Client/DeleteIntervention?id=",
  VALIDATE_CLIENT_PIN: "/Client/ValidatePin?",
  GET_QUESTIONS: "/Common/GetQuestionnaire",
  GET_QUESTIONS_BY_Id: "/Client/GetClientQuestionnaireById?id=",
  GET_QUESTION_BY_CLIENT_ID:
    "/Client/GetClientQuestionnaireByClientId?clientId=",
  INSERT_CLIENT_QUESTIONNAIRE: "/Client/InsertClientQuestionnaire",
  DELETE_QUESTIONNAIRE: "/Client/DeleteClientQuestionnaire?id=",

  INSERT_ELIGIBILITY_QUEUE: "/Eligibility/InsertEligibilityQueue",
  MOVE_CLIENT_SITE: "/Client/MoveClientsToSites",

  GET_CLIENT_COMPLIANCE: "/Client/GetAllCompliance?clientId=",

  COMPLETE_COMPLIANCE: "/Client/CompleteCompliance?clientId=",
  DELETE_MANUALLY_COMPLETED_COMPLIANCE: "/Client/DeleteManuallyCompletedCompliance",
  GENERATE_CLIENT_CREDS: "/Client/GenerateClientCredentials",
  GET_CLIENT_USERNAME: "/Client/GetClientUserName",
  RESET_CLIENT_PASSWORD: "/Client/ResetClientPassword",
  UPDATE_PRIVATE_PAY: "/Insurance/UpdateClientPrivatePay",
  GET_PRIVATE_PAY_STATUS: "/Insurance/GetClientPrivatePayStatus?clientId=",
  GET_INTERVENTION_DURATION: "/Common/GetInterventionDurations",
  GET_INTERVENTION_LOCATION: "/Common/GetInterventionLocations",

  // ---------------------------Signature-------------------------------------

  INSERT_CLIENT_TREATMENT_PLAN_SIGN: "/Signature/ApplyClientSignature",
  INSERT_STAFF_TREATMENT_PLAN_SIGN: "/Signature/ApplyStaffSignature",
  GET_CLIENT_TREATMENT_SIGN_BY_CLIENT_ID:
    "/Signature/GetClientTreatmentPlanSign?clientId=",
  GET_CLIENT_TREATMENT_SIGN_BY_PLAN_ID:
    "/Signature/GetClientTreatmentPlanSign?treatmentPlanId=",
  GET_STAFF_TREATMENT_SIGN_BY_CLIENT_ID:
    "/Signature/GetStaffTreatmentPlanSignature?clientId=",
  GET_STAFF_TREATMENT_SIGN_BY_PLAN_ID:
    "/Signature/GetStaffTreatmentPlanSignature?treatmentPlanId=",
  DELETE_CLIENT_PLAN_SIGN: "/Signature/DeleteClientSignature",
  DELETE_STAFF_PLAN_SIGN: "/Signature/DeleteStaffSignature",
  VALIDATE_STAFF_PIN: "/Staff/ValidatePin?pin=",
  GET_CLIENT_CURRENT_VITAL: "/Client/GetClientCurrentVital?clientId=",
  INSERT_AUTHORIZATION: "/Client/InsertAuthorization",
  GET_AUTHORIZATION_BY_CLIENT_ID: "/Client/GetAuthorizationByClientId",
  DELETE_AUTHORIZATION: "/Client/DeleteAuthorization?id=",
  GET_AUTHORIZATION_BY_ID: "/Client/GetAuthorizationById?id=",
  UPDATE_AUTHORIZATION: "/Client/UpdateAuthorization",
  GET_CLIENT_COMMUNICATION_PREF: "/Client/GetClientCommunicationPref?clientId=",
  POST_CLIENT_COMMUNICATION_PREF: "/Client/AddClientCommunicationPref",
  // ---------------------------Stored Document-------------------------------------
  POST_CLIENT_STORED_DOCUMENT: "/Client/InsertClientStoredDocument",
  POST_CLIENT_STORED_DOCUMENT_BY_CLIENT_ID:
    "/Client/GetClientStoredDocumentByClientId",
  CLIENT_STORED_DOCUMENTS_ATTACHMENTS:
    "/Client/GetClientDocumentFilesByClientId",
  POST_CLIENT_STORED_DOCUMENT_BY_ID: "/Client/GetClientDocumentById?docId=",
  POST_CLIENT_ATTACHED_DOCUMENT: "/Client/AddAttachmentToDocument",
  DELETE_CLIENT_DOCUMENT: "/Client/DeleteClientDocument?docId=",
  DELETE_CLIENT_ATTACHMENT: "/Client/DeleteClientAttachment",
  UPDATE_CLIENT_STORED_DOCUMENT: "/Client/UpdateClientStoredDocument",
  DELETE_CLIENT_STATUS: "/Clinic/DeleteClientStatus?id=",

  // --------------------------Emergence Contact --------------------------------

  ADD_EMERGENCY_CONTACT: "/Client/InsertEmergencyContacts",
  DELETE_EMERGENCY_CONTACT: "/Client/DeleteEmergencyContacts?id=",
  GET_EMERGENCY_CONTACT_BY_ID: "/Client/GetEmergencyContactsById?id=",
  GET_EMERGENCY_CONTACT_BY_CLIENT_ID:
    "/Client/GetEmergencyContactsByClientId?clientId=",
  UPDATE_EMERGENCY_CONTACT: "/Client/UpdateEmergencyContact",

  // -------------Common---------------s
  GET_CLINIC_SERVICES: "/Service/GetClinicServices?clinicId=",
  GET_GENDER: "/Common/GetGenders",
  GET_STATE: "/Common/GetState",
  GET_RACE: "/Common/GetRace",
  GET_SHIFTS: "/Common/GetShifts",
  GET_EVENT_STATUS: "/Common/GetEventStatus",
  GET_BILLINGUNIT: "/Common/GetBillingUnit",
  GET_ETHNICITY: "/Common/GetEthnicity",
  GET_SMOKING_STATUS: "/Common/GetSmokingStatusList",
  GET_MARITAL_STATUS: "/Common/GetMaritalStatus",
  GET_RELATIONS: "/Common/GetRelations",
  GET_DIAGNOSIS: "/Common/GetDiagnosisByDate?",
  GET_TREATMENTPLAN_STATUS: "/Common/GetTreatmentPlanStatuses",
  GET_AUTHORIZATION_STATUS: "/Common/GetAuthorizationStatuses",
  GET_COMMUNICATION_METHODS: "/Common/GetCommunicationMethods",
  GET_IMMUNIZATION: "/Common/GetImmunization",
  GET_ADMINISTRATION_SITE: "/Common/GetAdministrationSite",
  GET_ADMINISTRATION: "/Common/GetAdministrationRoute",
  GET_MANUFACTUREER: "/Common/GetManufacturer",
  GET_EDUCATION_LEVEL: "/Common/GetEducationLevel",
  GET_DISCHARGE_REASON: "/Common/GetDischargeReasons",
  GET_TIME_RECORDING_METHODS: "/Common/GetTimeRecordingMethods",
  GET_ALL_PLACE_OF_SERVICETYPE: "/Common/GetAllPlaceOfServiceType",
  GET_ALL_PAYERS: "/Common/GetAllPayers",
  GET_TP_SERVICE_TYPES: "/Common/GetTpServiceTypes",
  GET_TP_STAFF_TYPES: "/Client/GetTpStaffTypesDll",
  GET_EVENT_CATEGORY: "/Scheduler/GetCalendarCategories",
  // -------------Auth---------------s
  // -------------Auth---------------s

  CHANGE_PASSWORD: "/Auth/ChangePassword",
  FORGOT_PASSWORD: "/Auth/ForgotPassword?userName=",

  SYNC_STAFF_STATUS: "/Staff/SyncStaffOnlineStatus",

  GET_CURRENTLY_LOGGEDIN_STAFF: "/Staff/GetCurrentlyLoggedInStaff",

  VERIFY_OTP: "/Auth/VerifyOTP",
  UPDATE_PASSWORD: "/Auth/UpdatePassword",
  LOGOUT_USER: "/Auth/LogOut",

  // -------------Ref Provider Settings---------------s

  INSERT_CLINIC_REFERRING_PROVIDER:
    "/ClinicReferringProvider/InsertClinicReferringProvider",
  GET_CLINIC_REFERRING:
    "/ClinicReferringProvider/GetClinicReferringProviderByClinicId?clinicId=",
  DELETE_CLINIC_REFERRING:
    "/ClinicReferringProvider/DeleteClinicReferringProvider?id=",
  REACTIVATE_CLINIC_REFERRING:
    "/ClinicReferringProvider/ReactivateReferringProvider?id=",
  GET_CLINIC_REF_BY_ID:
    "/ClinicReferringProvider/GetClinicReferringProviderById?id=",
  UPDATE_CLINIC_REFERRING_PROVIDER:
    "/ClinicReferringProvider/UpdateClinicReferringProvider",

  // -------------Telehealth Video---------------s

  GET_TELEHEALTH_TOKEN: "/Scheduler/GetTeleheathAccessToken",
  CLOSE_TELEHEALTH_SESSION: "/Scheduler/CloseTelehealthSession?roomId=",
  SEND_TELEHEALTH_LINK_TO_CLIENT: "/Notification/SendTelehealthLinkToClient",
  SEND_TELEHEALTH_LINK_TO_CLIENT_BY_EVENT_ID: "/Notification/SendTelehealthLinkToClientByEvent",

  // -------------RefSource Settings---------------s

  INSERT_REF_SOURCE: "/ClinicReferralSource/InsertClinicReferralSource",
  GET_REF_SOURCE:
    "/ClinicReferralSource/GetClinicReferralSourceByClinicId?clinicId=",
  DELETE_REF_SOURCE: "/ClinicReferralSource/DeleteClinicReferralSource?id=",
  REACTIVATE_REF_SOURCE: "/ClinicReferralSource/ReactivateReferringSource?id=",
  GET_REF_SOURCE_BY_ID: "/ClinicReferralSource/GetClinicReferralSourceById?id=",
  UPDATE_REF_SOURCE: "/ClinicReferralSource/UpdateClinicReferralSource",

  UPDATE_CERTIFICATES: "/Clinic/UpdateClinicCertificates",
  DELETE_CERTIFICATE: "/Clinic/DeleteClinicCertificates?id=",
  INSERT_CERTIFICATES: "/Clinic/InsertClinicCertificates",

  // -------------Client Ref Source---------------s

  INSERT_CLIENT_REF_SOURCE: "/Referral/InsertClientReferralSource",
  GET_CLIENT_REF_SOURCE:
    "/Referral/GetClientReferralSourceByClientId?clientId=",
  INSERT_CLIENT_REF_PROVIDER: "/Referral/InsertClientReferralProvider",
  GET_CLIENT_REF_PROVIDER: "/Referral/GetClientReferralProvider?clientId=",

  UPLOAD_CLINIC_LOGO: "/Clinic/UpdateClinicLogo",
  GET_CLINIC_LOGO: "/Clinic/GetClinicLogo?clinicId=",

  // -------------Client Flags---------------s
  INSERT_CLIENT_FLAG: "/Clinic/InsertClinicClientFlags",
  GET_CLINIC_CLIENT_FLAGS: "/Clinic/GetClinicClientFlagsByClinicId?isActive=true",
  DELETE_FLAG: "/Clinic/DeleteClinicClientFlags?id=",
  GET_FLAG_BY_ID: "/Clinic/GetClinicClientFlagsById?id=",
  UPDATE_CLIENT_FLAG: "/Clinic/UpdateClinicClientFlags",

  // -------------Client Tags---------------s
  INSERT_CLINIC_TAG: "/Clinic/InsertClinicTags",
  GET_CLINIC_CLIENT_TAGS: "/Clinic/GetClinicTagsByClinicId?clinicId=",
  DELETE_TAG: "/Clinic/DeleteClinicTags?id=",
  GET_TAG_BY_ID: "/Clinic/GetClinicTagsById?id=",
  UPDATE_CLINIC_TAG: "/Clinic/UpdateClinicTags",
  ASSIGN_CLINIC_TAGS: "/Clinic/AssignTagToClinicDocument",

  // -------------Client Status----------------
  ADD_CLIENT_STATUS: "/Clinic/InsertClientStatus",
  EDIT_CLIENT_STATUS: "/Clinic/UpdateClientStatusByStatusId",
  GET_CLIENT_STATUS_BY_ID: "/Clinic/GetClientStatusByStatusId?id=",

  // -------------Roles Permissions---------------s

  GET_ROLE_MODULES: "/RolesAndPermission/GetRoleModulePermissionAccess?roleId=",
  UPDATE_ROLE_PERMISSIONS:
    "/RolesAndPermission/UpdateRoleModulePermissionAccess",
  GET_USER_PERMISSIONS: "/RolesAndPermission/GetLogInUserPermissionAccess",

  // -------------Site---------------
  GET_CLINIC_SITES: "/Site/GetClinicSites",
  GET_CLINIC_SITES_DDL: "/Site/GetClinicSiteDDL",
  GET_STAFF_GLOBAL_VIEW_SITES: "/Staff/GetStaffGlobalViewSites",
  SET_STAFF_DEFAULT_SITE: "/Staff/SetStaffDefaultSite",

  // -------------Service Manager---------------
  INSERT_Services: "/Service/InsertService",
  UPDATE_SERVICE: "/Service/UpdateService",

  GET_SERVICE_BY_PROVIDER_ID: "/Service/GetClinicServices?clinicId=",
  DELETE_Services: "/Service/DeleteService?id=",
  UPDATE_SERVICE_RATES: "/Service/UpdateServiceRate",
  ADD_SERVICE_RATES: "/Service/InsertServiceRate",

  GET_Services_BY_ID: "/Service/GetServiceById?id=",
  DELETE_SERVICE_RATE: "/Service/DeleteServiceRate?id=",
  GET_SERVICERATE_BY_ID: "/Service/GetServiceRateById?id=",
  GET_SERVICE_REACTIVE: "/Service/ReActivateService?serviceId=",

  // -------------Scheduler---------------
  SCHEDULER: {
    GET_EVENTS: "/Scheduler/GetEvents",
    CREATE_EVENT: "/Scheduler/CreateEvent",
    UPDATE_EVENT: "/Scheduler/UpdateEvent",
    DELETE_EVENT: "/Scheduler/DeleteEvent?id=",
    UPDATE_STAFF_STATUS_COLOR: "/Scheduler/UpdateStaffStatusColor",
  },
  // -------------Messages---------------
  INSERT_MESSAGE: "/MessageCenter/InsertMessage",
  INSERT_CLIENT_MESSAGE: "/ClientMessage/InsertMessage",
  GET_STAFF_MESSAGES: "/MessageCenter/GetStaffMessages",
  GET_CLIENT_MESSAGES: "/ClientMessage/GetClientMessages",
  GET_MESSAGE_ATTACHMENT_BY_ID: "/MessageCenter/GetMessageAttachmentById?id=",
  GET_CLIENT_MESSAGE_ATTACHMENT_BY_ID: "/ClientMessage/GetMessageAttachmentById?id=",
  GET_STAFF_MESSAGES_BY_ID: "/MessageCenter/GetStaffMessagesById",
  GET_PERSONAL_GROUP: "/MessageCenter/GetPersonalGroup",
  CREATE_PERSONAL_GROUP: "/MessageCenter/CreatePersonalGroup",
  DELETE_PERSONAL_GROUP: "/MessageCenter/DeletePersonalGroup",
  // GET_PERSONAL_GROUP_BY_ID: "/MessageCenter/GetPersonalGroupById",
  UPDATE_PERSONAL_GROUP: "/MessageCenter/UpdatePersonalGroup",
  GET_STAFF_BY_PG_IDS: "/MessageCenter/GetStaffByPersonalGroupIds",
  GET_STAFF_MESSAGE_UNREAD_COUNT: "/MessageCenter/GetUnreadCount",
  GET_CLIENT_MESSAGE_UNREAD_COUNT: "/ClientMessage/GetUnreadCount",
  MULTI_CLIENT_LIST: "/Client/GetMultipleClientInfo",
  SAVE_DEFAULT_GOALS: "/Clinic/InsertDefaultGoalObjInterventions",
  UPDATE_DEFAULT_GOALS: "/Clinic/UpdateDefaultGoalObjInterventions",
  UPDATE_DEFAULT_OBJECTIVES: "/Clinic/UpdateDefaultObjective",
  UPDATE_DEFAULT_INTERVENTION: "/Clinic/UpdateDefaultIntervention",
  DELETE_DEFAULT_OBJECTIVE: "Clinic/DeleteDefaultObjective",
  DELETE_DEFAULT_INTERVENTION: "Clinic/DeleteDefaultIntervention",
  ADD_DEFAULT_INTERVENTION: "Clinic/InsertDefaultInterventions",
  UPDATE_DEFAULT_GOAL: "/Clinic/UpdateDefaultGoal",
  ADD_DEFAULT_OBJECTIVE: "Clinic/InsertDefaultObjective",
  GET_DEFAULT_GOAL_BY_ID: "Clinic/GetDefaultGoalsById",
  TRASH_MESSAGE: "/MessageCenter/TrashMessage",
  TRASH_CLIENT_MESSAGE: "/ClientMessage/TrashMessage",
  UN_TRASH_MESSAGE: "/MessageCenter/UnTrashMessage",
  UN_TRASH_CLIENT_MESSAGE: "/ClientMessage/UnTrashMessage",
  DELETE_MESSAGE: "/MessageCenter/DeleteMessage",
  DELETE_CLIENT_MESSAGE: "/ClientMessage/DeleteMessage",
  MARK_UN_READ: "/MessageCenter/MarkUnRead",
  MARK_CLIENT_MESSAGE_UN_READ: "/ClientMessage/MarkUnRead",
  MARK_READ: "/MessageCenter/MarkRead",
  MARK_CLIENT_MESSAGE_READ: "/ClientMessage/MarkRead",
  MOVE_TO_CUSTOM_LABEL: "/MessageCenter/MoveToCustomLabel",
  MOVE_TO_INBOX: "/MessageCenter/MoveToInbox",
  GET_PERSONAL_LABEL: "/MessageCenter/GetPersonalLabel",
  CREATE_PERSONAL_LABEL: "/MessageCenter/CreatePersonalLabel",
  DELETE_PERSONAL_LABEL: "/MessageCenter/DeletePersonalLabel",
  UPDATE_PERSONAL_LABEL: "/MessageCenter/UpdatePersonalLabel",

  //-----------------Position------------------------
  POST_ADD_POSITION: "/StaffPositions/InsertStaffPosition",
  GET_POSITION_LIST: "/StaffPositions/GetStaffPositionByStaffId?staffId=",
  DELETE_POSITION: "/StaffPositions/DeleteStaffPosition?staffPositionId=",
  EDIT_POSITION: "/StaffPositions/UpdateStaffPosition",
  GET_POSITION_BY_ID: "/StaffPositions/GetStaffPositionById?staffPositionId=",

  // ----------------------Certificate--------------------------
  GET_CLINIC_CERTIFICATE: "/Clinic/GetClinicCertificates",
  POST_CLINIC_CERTIFICATE: "/Staff/InsertStaffCertification",
  GET_STAFF_CERTIFICATE: "/Staff/GetStaffCertificationByStaffId?staffId=",
  DELETE_STAFF_CERTIFICATE: "/Staff/DeleteStaffCertification?id=",
  UPDATE_STAFF_CERTIFICATE: "/Staff/UpdateStaffCertification",
  GET_STAFF_CERTIFICATE_BY_ID: "/Staff/GetStaffCertificationById?id=",
  ASSIGN_CERTIFICATE_TO_STAFFS: "/staff/AssignCertificationToStaff",

  // ------------------------------Task manager-------------------------
  INSERT_TASK: "/TaskManager/InsertTask",
  GET_ALL_TASKS_BY_SEARCH: "/TaskManager/GetAllTasks",
  UPDATE_TASK: "/TaskManager/UpdateTask",
  GET_TASK_BY_ID: "/TaskManager/GetTaskById?id=",
  MARK_TASK_COMPLETE: "/TaskManager/MarkTaskComplate",
  DELETE_TASK: "/TaskManager/DeleteTask?id=",
  INSERT_DISCUSSION: "/TaskManager/InsertTaskDiscussion",
  GET_TASK_DISCUSSION_BY_ID: "/TaskManager/GetTaskDiscussionById?id=",
  GET_TASK_DISCUSSION_BY_ASSIGNED_ID:
    "/TaskManager/GetTaskDiscussionByAssignedId?assignedToId=",
  DELETE_TASK_DISCUSSION: "/TaskManager/DeleteTaskDiscussion?id=",
  UPDATE_DISCUSSION: "/TaskManager/UpdateTaskDiscussion",
  UPDATE_TASK_STATUS: "/TaskManager/UpdateTaskStatus",
  // ------------------------------Contact notes----------------------
  INSERT_CONTACT_NOTES: "/Client/InsertContactNotes",
  UPDATE_CONTACT_NOTES: "/Client/UpdateContactNotes",
  DELETE_CONTACT_NOTES: "/Client/DeleteContactNotes?id=",
  GET_CONTACT_NOTES_BY_CLIENT_ID: "/Client/GetContactNotesByClientId?clientId=",
  GET_CONTACT_NOTES_BY_ID: "/Client/GetContactNotesById?id=",

  //  --------------------------------Employment----------------------------

  INSERT_CLIENT_EMPLOYEMENTS: "/Client/InsertClientEmployements",
  GET_CLIENT_EMPLOYEMENTS: "/Client/GetClientEmployements?clientId=",
  GER_CLIENT_EMPLOYEMENT_BY_ID: "/Client/GetClientEmployementById?Id=",
  UPDATE_CLIENT_EMPLOYEMENTS: "/Client/UpdateClientEmployements",
  DELETE_CLIENT_BACKGROUNDS: "/Client/DeleteClientEmployements?id=",

  // ------------------------------Immunization--------------------------
  INSERT_CLIENT_IMMUNIZATION: "/ClientImmunization/InsertClientImmunization",
  UPDATE_CLIENT_IMMUNIZATION: "/ClientImmunization/UpdateClientImmunization",
  DELETE_CLIENT_IMMUNIZATION:
    "/ClientImmunization/DeleteClientImmunization?id=",
  GET_CLIENT_IMMUNIZATION_BY_CLIENT_ID:
    "/ClientImmunization/GetClientImmunizationByClientId?clientId=",
  GET_CLIENT_IMMUNIZATION_BY_ID:
    "/ClientImmunization/GetClientImmunizationById?id=",

  // ----------------------------------Education--------------------------------
  GET_CLIENT_EDUCATION: "/Client/GetClientEducation?clientId=",
  GET_CLIENT_EDUCATION_BY_ID: "/Client/GetClientEducationById?id=",
  INSERT_CLIENT_EDUCATION: "/Client/InsertClientEducation",
  UPDATE_CLIENT_EDUCATION: "/Client/UpdateClientEducation",
  DELETE_CLIENT_EDUCATION: "/Client/DeleteClientEducation?id=",

  // ---------------------------DisCharge-------------------------
  CREATE_DISCHARGE: "/Client/DischargeClient",
  GET_CLIENT_DISCHARGEINFOR: "/Client/GetClientDischargeInfo?clientId=",

  GET_DOC_PLACE_OF_SERVICE: "/Document/GetDocumentPrefPlaceOfServiceByClinicId",
  ASSIGN_DOC_PLACE_OF_SERVICE: "/Document/AssignPlaceOfServiceToClinic",
  GET_ALL_PLACE_OF_SERVICE: "/Common/GetAllPlaceOfServices",

  // --------------------------Dashboard------------------------------
  UPDATE_CLIENT_PRIMARY_CARE_PHYSICIAN: "/Client/UpdateClientPrimaryCarePhysician",
  GET_CLIENT_PRIMARY_CARE_PHYSICIAN: "/Client/GetClientPrimaryCarePhysician?clientId=",
  UPDATE_CLIENT_PEDIATRICATION: "/Client/UpdateClientPediatrician",
  GET_CLIENT_PEDIATRICATION: "/Client/GetClientPediatrician?clientId=",
  GET_CLINIC_DASHBOARD: "/Dashboard/GetClinicDashboard",
  GET_CLIENTS_BY_SITES: "/Dashboard/GetClientsBySite?returnTotCountOnly=true",
  GET_NO_SESSION_CLIENTS: "/Dashboard/GetClientsByLastSeen",
  GET_CLIENT_BY_SITE: "/Dashboard/GetClientsBySite?",
  GET_CLIENT_BY_INSURANCE: "/Dashboard/GetClientsByInsurance",
  GET_STAFF_NOT_LOGGEDIN: "/Staff/GetLoggedInStaffWithinDays?",
  GET_REMAINING_AUTH_UNITS: "/Dashboard/GetAuthRemainingUnitRange",
  GET_CLIENT_FLAGS_CASELOAD: "/Dashboard/GetStaffClients",
  GET_DOCUMENT_REVIEWED_LIST: "/Dashboard/GetDocumentReviewList",
  GET_AUTH_EXPIRATION: "/Dashboard/GetAuthExpiringWithinDays",
  GET_BILLING_SNAPSHOT: "/Dashboard/GetBillingSnapShot",
  GET_CLIENT_COUNT: "/Dashboard/GetClientsCount",
  GET_CLIENT_DIAGNOSIS_COUNT: "/Dashboard/GetClientDiagnosisCount",
  GET_SERVICE_UNITS_RENDER: "/Dashboard/GetServiceUnitsRendersByMonth?fromDate=",
  GET_BILLING_COUNTS: "/Dashboard/GetBillingCounts?fromDate=",


  // ---------------------------------Audit-----------------------------
  GET_STAFF_AUDIT_LOG_ACTIONS: "/Common/GetStaffAuditLogActions",
  GET_AUDIT_AFFECTED_SECTIONS: "/Common/GetAuditAffectedSections?id=",
  GET_CLIENT_AUDIT_LOG_ACTIONS: "/Common/GetClientAuditLogActions",
  GET_CLIENT_AUDIT_LOG: "/AuditLog/GetClientAuditLog",
  GET_CLINIC_AUDITLOG: "/AuditLog/GetClinicAuditLog",
  GET_STAFF_AUDIT_LOG: "/AuditLog/GetStaffAuditLog",

  // -------------------------------Service Auth--------------------
  GET_AUTH_BY_CLINIC: "/Client/GetAuthByClinic",

  // -------------------------------Clinic------------------------------
  GET_CLINIC_PAYERS: "/Clinic/GetClinicPayers?clinicId=",
  POST_CLINIC_PAYERS: "/Clinic/ClinicPayers",
  ASSIGN_STAFF_TO_STAFF: "/Staff/AssignStaffsToStaff",
  UPDATE_CLINIC: "/Clinic/UpdateClinic",
  GET_CLINIC: "/Clinic/GetClinicById?id=",
  IMPORT_CLIENTS: "/Client/ImportClients",
  DOWNLOAD_CLIENT_TEMPLATE: "/Client/DownloadClientTemplate",

  // ---------------------------------Billing comment-----------------------
  GET_BILLING_COMMENT_BY_DOCUMENT_ID: "/Document/GetBillingCommentByDocumentId?docuementId=",
  INSERT_BILLING_COMMENT: "/Document/InsertBillingComment",
  UPDATE_BILLING_COMMENT: "/Document/UpdateBillingComment",
  DELETE_BILLING_COMMENT: "/Document/DeleteBillingComment?id=",
  GET_BILLING_COMMENT_BY_ID: "/Document/GetBillingCommentById?id=",
  GET_CLIENT_BILLING_CLAIMS: "/Billing/GetBillingClaimsForClient",
  GET_EDI_BY_BATCH_ID: "/Billing/GetEdiByBatchId?id=",

  //// Document chart

  POST_STAFF_MOST_DOCUMENT: "/Graph/GetStaffMostDocuments",
  POST_CLIENT_MOST_DOCUMENT: "/Graph/GetClientMostDocuments",
  POST_BILLING_STATUS_METRICS: "/Graph/GetBillingStatusMetrics",
  POST_BILLING_BY_PAYER_METRICS: "/Graph/GetBillingByPayerMetrics",
  POST_BILLING_BY_STATUS_METRICS: "/Graph/GetDocumentCountByDocumentStatus",
  POST_BILLING_BY_TEMPLATE_METRICS: "/Graph/GetDocumentCountByDocumentTemplate",
  POST_DOC_METRICS_BY_SERVICE_CODE: "/Graph/GetDocumentsByServiceCode",
  //-----------------------------------Clinic Client Compliance-------------------

  CREATE_CLIENT_COMPLIANCE: "/Clinic/CreateClientCompliance",
  UPDATE_CLIENT_COMPLIANCE: "/Clinic/UpdateClientCompliance",
  DELETE_CLIENT_COMPLIANCE: "/Clinic/DeleteClientCompliance?clientComplianceId=",
  GET_CLIENT_COMPLIANCE_BY_ID: "/Clinic/GetClientComplianceById?clientComplianceId=",
  GET_CLIENT_COMPLIANCE_FOR_CLINIC: "/Clinic/GetClientComplianceForClinic",
  GET_CLIENT_COMPLIANCE_FOR_CLINIC_DDL: "/Clinic/GetClientComplianceForClinicDDL?complianceId=",
  GET_SERVICE_CODES_WOMODIFIERS: "/Service/GetServiceCodesWoModifiers?isActive=1",
  GET_COMPLIANCE_REPORT: "/Clinic/GetComplianceReport",
  EXPORT_COMPLIANCE_REPORT: "/Clinic/ExportComplianceReportToExcel",

  SAVE_MULTI_AUTH: "/Client/InsertMultipleAuthorization",
  GET_SERVICE_CODES_WO_MODIFIERS: '/Service/GetServiceCodesWoModifiers?isActive=1',
  GET_SERVICE_BY_SERVICE_CODE: '/Service/GetServicesBySrvCodes',
  CHECK_COMPLIANCE: "/Document/CheckCompliance?documentTemplateId=",


  UPDATE_CLIENTS_STATUS: "/Client/UpdateClientsStatus",

  GET_BILLING_TRANS_ITEM_BY_CLAIM_ID: "/Billing/Get837BillingTransItemsByClaimId?claimId=",

  GET_CLINIC_DEFAULT_GOALS: "/Clinic/getClinicDefaultGoals",
  DELETE_CLINIC_DEFAULT_GOALS: "/Clinic/DeleteDefaultGoal?id=",
  ASSIGN_DEFAULT_GOALS_TO_CLIENTS: "/Clinic/AssignDefaultGoalsToClients",
  GET_SERVICE_SETS_LIST: "/Service/GetClinicServicesSet",
  DELETE_SERVICE_SET: "/Service/DeleteServiceSet?id=",
  INSERT_SERVICE_SET: "/Service/InsertServiceSet",
  UPDATE_SERVICE_SET: "/Service/UpdateServiceSet",
  ASSIGN_SET_TO_STAFF: "/Service/AssignServiceSetToStaff",
  ASSIGN_SET_TO_CLIENT: "/Service/AssignServiceSetToClient",

  AVAILABLE_SERVICE_LIST: "/Service/GetClinicServices?clinicId=",
  GET_SERVICE_DDL_BY_CLINIC_ID: "/Service/GetServiceDDLByClinicId",
  GET_SERVICE_SET_DDL: "/Service/GetServiceSetDDL?serviceSetFor=",

  GET_BYPASS_TOKEN: "/Auth/GetByPassAccessToken",
  GET_AUTH_SET_LIST: "/Clinic/GetClinicAuthorizationSet",
  DELETE_AUTH_SET_LIST: "/Clinic/DeleteAuthorizationSet",
  CREATE_AUTH_SET: "/Clinic/InsertAuthorizationSet",
  UPDATE_AUTH_SET: "/Clinic/UpdateAuthorizationSet",
  CHECK_DOC_AUTH: "/Document/GetDocumentAuthValidation",


  //  Default goals

  INSERT_DEFAULT_GOALS_TEMPLATE: "/Clinic/InsertDefaultGoalsTemplate",
  GET_CLINIC_DEFAULT_GOALS_TEMPLATES: "/Clinic/GetClinicDefaultGoalsTemplates",
  GET_CLINIC_DEFAULT_GOALS_TEMPLATES_INFO: "/Clinic/GetDefaultGoalsTemplatesInfo?templateId=",
  DELETE_DEFAULT_GOALS_TEMPLATE: "/Clinic/DeleteDefaultGoalsTemplate?id=",
  UPDATE_DEFAULT_GOALS_TEMPLATES: "/Clinic/UpdateDefaultGoalsTemplate",
  GET_DOC_GOAL_TEMPLATES: "/Clinic/GetClinicDefaultGoalsTemplates",
  CHECK_STAFF_CERTIFICATE_EXPIRY: "/Staff/GetStaffExpiredRestrictiveCertificates",


  // Clinic File
  GET_CLINIC_DOCUMENT_S3_SIGNED_URL: "/Clinic/GetClinicDocumentS3SignedUrl",
  GET_CLINIC_STORED_DOCUMENT_BY_CLINIC_ID: "/Clinic/GetClinicStoredDocumentByClinicId",
  GET_CLINIC_S3_DOCUMENT_URL_BYID: "/Clinic/GetClinicS3DocumentUrlById?id=",
  GET_CLINIC_DOCUMENT_BYID: "/Clinic/GetClinicDocumentById?docId=",
  DELETE_CLINIC_DOCUMENT_TAG: "/Clinic/DeleteClinicDocumentTag?id=",
  DELETE_CLINIC_DOCUMENT: "/Clinic/DeleteClinicDocument?docId=",

  //ERX
  GET_PRESCRIPTION_LIST: "/Eprescription/GetDosspotClinicianRoles",
  SAVE_CLINICIAN: "/Eprescription/AddClinician",
  EDIT_CLINICIAN: "/Eprescription/EditClinician",
  GET_CLINICIAN_LIST: "/Eprescription/GetAllDosspotClinician",
  GET_CLINIC_DOSESPOT_ID: "/Eprescription/GetCinicDosespotClinicId",
  ADD_ERX_CLINIC: "/Eprescription/AddErxClinic",
  GET_DOSESPOT_SSO_URL: "/Eprescription/GetDosespotSSOUrl?clientId=",
  GET_DOSESPOT_STAFF_LIST: "/Eprescription/GetStaffListForErx",
  GET_STAFF_DOSESPOT_ID: "/Eprescription/GetStaffClinicianId",
  REMOVE_CLINICIAN: "/Eprescription/RemoveClinician?clinician=",

  // staff
  GET_TP_STAFF_TYES: "/Client/GetTpStaffTypesDll",

  //Review Goals
  INSERT_REVIEW_GOAL: "/Client/InsertReviewGoal",
  GET_REVIEW_GOAL_BY_GOAL_ID: "/Client/GetReviewGoalByGoalId",
  UPDATE_REVIEW_GOAL: "/Client/UpdateReviewGoal",
  DELETE_REVIEW_GOAL: "/Client/DeleteReviewGoal",

  // Notification 

  GET_CLINIC_NOTIFICATION_SETTING: "/Notification/GetClinicNotificationSettings?alertType=",
  INSERT_CLINIC_NOTIFICATION_SETTING: "/Notification/InsertClinicNotificationSettings",
  GET_STAFF_NOTIFICATION_SETTING: "/Notification/GetStaffNotificationSettings",
  INSERT_STAFF_NOTIFICATION_SETTING: "/Notification/InsertStaffNotificationSettings",
  GET_STAFF_NOTIFICATION_MEDIUM_SETTING: "/Notification/GetStaffNotificationMediumSettings",
  INSERT_STAFF_NOTIFICATION_MEDIUM_SETTING: "/Notification/InsertStaffNotificationMediumSettings",
  GET_ASSIGNED_ROLES_FOR_NOTIFICATION_SETTINGS: "/Notification/GetAssignedRolesForNotificationSettings?alertType=",

  // view Trans
  GET_AUTH_TRANSACTION: "/Client/GetAuthTransactions?authId=",

  // Auth History
  GET_AUTH_HISTORY: "/Client/GetAuthHistoryByAuthId?authId=",


  GET_STAFF_TIME_CLOCK: "/Staff/GetStaffTimeClock",
  GET_TIME_CLOCK_ARCHIVE_BY_ID: "/Staff/GetTimeClockArchiveById",
  DELETE_STAFF_CLOCK: "/Staff/DeleteStaffTimeClock",
  APPROVE_TIME_CLOCK: "/Staff/ApprovedTimeClock",
  CREATE_NEW_TIMECLOCK: "/Staff/InsertStaffTimeClock",
  GET_STAFF_CLOCK_STATUS: "/Staff/GetStaffTimeClockStatus",
  GET_ELIGIBILITY_SELECTION_ITEMS: "Common/GetEligibilityBillingAndDocMethods",
  GET_DOCUMENT_ELIGIBILITY_CHECKS: "/Document/CheckEligibilityAndSettings?clientIds=",
  ASSIGN_NOTIFICATION_SETTING_TO_STAFF_OR_ROLE: "/Notification/AssignNotificationSettingsToStaffOrRole",
  GET_STAFF_TIME_CLOCK_REPORT: "/Staff/GetStaffTimeClockReport",
  GET_STAFF_TIME_CLOCK_REPORT_EXCEL: "/Staff/ExportTimeClockReportIntoExcel",


  // Document service
  UPDATE_DOCUMENT_SERVICE: "/Document/UpdateDocumentService",
  UPDATE_DOCUMENT_SERVICE_DATE: "/Document/UpdateDocumentServiceDate",
  UPDATE_DOCUMENT_DIAGNOSIS: "/Document/UpdateDocumentDiagnosis",
  UPDATE_DOCUMENT_SITE_OF_SERVICE: "/Document/UpdateDocumentSiteOfService",
  UPDATE_DOCUMENT_PLACE_OF_SERVICE: "/Document/UpdateDocumentPlaceOfService",

  // Risk 
  GET_RISK_DDL: "/Document/GetRiskDataByType?riskType=",
  GET_DOC_PRINT_META_DATA: "/Document/GetDocumentPrintMetadata",
  GET_MULTIPLE_DOCUMENT_DETAIL: "/Document/GetDocumentByDocumentIds",

  // Time Clock
  GET_TIME_CLOCK_BY_ID: "/Staff/GetTimeClockById?id=",
  UPDATE_STAFF_TIME_CLOCK: "/Staff/UpdateStaffTimeClock",
  GET_CLIENT_BY_GENDER_COUNT: "/Dashboard/GetClientByGenderCount",
  GET_CLIENT_NEXT_APPOINTMENTS: "/Client/GetClientNextAppointment",

  // treatmentPlan setting
  GET_CLINIC_TP_SERVICE_TYPE: "/Clinic/GetClinicTpServiceTypes",
  GET_CLINIC_TP_STAFF_TYPE: "/Clinic/GetClinicTpStaffTypes",
  INSERT_OR_UPDATE_CLINIC_TP_SERVICE_TYPE: "/Clinic/InsertOrUpdateClinicTpServiceTypes",
  DELETE_CLINIC_TP_SERVICE_TYPES: "/Clinic/DeleteClinicTpServiceTypes",
  INSERT_OR_UPDATE_CLINIC_TP_STAFF_TYPES: "/Clinic/InsertOrUpdateClinicTpStaffTypes",
  DELETE_CLINIC_TP_STAFF_TYPES: "/Clinic/DeleteClinicTpStaffTypes",


  //clinic Skills
  GET_CLINIC_SKILLS: "/Clinic/GetClinicalSkills?skillName=",
  DELETE_CLINIC_SKILL: "/Clinic/DeleteClinicalSkill?id=",
  INSERT_CLINIC_SKILL: "/Clinic/InsertOrUpdateClinicalSkill",
  CHECK_DUPLICATE_CLIENTS: "/Client/CheckDuplicateClients",



  GET_STAFF_PLAN_TEMPLATES: "/Clinic/GetAllStaffPlanTemplates",
  GET_STAFF_PLAN_TEMPLATES_DETAIL: "/Clinic/GetStaffPlanTemplateById?id=",
  GET_STAFF_PLAN_TEMPLATE_PREF_BY_TEMPLATE_ID:
    "/Clinic/GetStaffPlanDocumentTemplatesSettings?templateId=",
  GET_STAFF_PLAN_GET_STAFF_GOALS: "/StaffPlanDocument/GetStaffGoals?staffId=",
  INSERT_STAFF_DOCUMENT: "/StaffPlanDocument/InsertStaffPlanDocuments",
  GET_DOCUMENT_LISTING: "/StaffPlanDocument/GetStaffPlanDocumentsList",
  UPLOAD_STAFF_DOC_ATTACHMENTS: "/StaffPlanDocument/UploadStaffPlanDocumentAttachments",
  DELETE_STAFF_DOC: "/StaffPlanDocument/DeleteStaffPlanDocuments?id=",
  GET_STAFF_DOC_BY_ID: "/StaffPlanDocument/GetStaffPlanDocumentById?id=",
  GET_STAFF_DOC_ATTACHMENT: '/StaffPlanDocument/GetStaffPlanDocumentAttachments?documentId=',
  GET_STAFF_PLAN_DOCUMENT_SIGNATURE: "/Signature/GetStaffPlanDocumentSignatures?documentId=",
  UPDATE_STAFF_DOCUMENT: "/StaffPlanDocument/UpdateStaffPlanDocuments",

  GET_TRAINING_LINKS: "/Dashboard/GetTrainingLinks",

  //calendar Setting
  GET_CALENDAR_CATEGORY: "Scheduler/getCalendarCategories",
  DELETE_CALENDAR_CATEGORY: "Scheduler/deleteCalendarCategories?id=",
  INSERT_CALENDAR_CATEGORY: "/Scheduler/AddOrUpdateCalendarCategories",
  UPDATE_CALENDAR_CATEGORY: "/Scheduler/AddOrUpdateCalendarCategories",
  GET_DEFAULT_CALENDAR_CATEGORY: "/Scheduler/GetCalendarSettings",
  UPDATE_DEFAULT_CALENDAR_CATEGORY: "/Scheduler/UpdateCalendarSettings",
  DELETE_STAFF_DOC_ATTACHMENT: "/StaffPlanDocument/DeleteStaffPlanDocumentAttachment?id=",

  //Export To Excel
  DAYS_SINCE_CLIENTS_LAST_SEEN_EXCEL: "/Dashboard/ExportClientsByLastSeenToExcel",
  EXPIRING_AUTHORIZATIONS_EXCEL: "Dashboard/ExportAuthExpiringWithinDaysToExcel",
  BILLING_TRANS_QUEUE_TO_EXCEL: "/Billing/Export837BillingTransQueueToExcel",
  BILLING_REMITTANCE_VIEW_TO_EXCEL: "/Billing/Export835BillingRemittanceToExcel",
  BILLING_REMITTANCE_CLAIM_VIEW_TO_EXCEL: "/Billing/Export835BillingClaimsToExcel",
  EXPORT_CLIENT_LEDGER_REPORT_TO_EXCEL: "/Client/ExportClientLedgerReportIntoExcel",


  //ServiceRulesmax
  GET_SERVICE_RULES: 'Service/GetClinicServiceRules',
  DELETE_SERVICE_RULE: 'Service/DeleteServiceRule?serviceRuleId=',
  CREATE_SERVICE_RULE: 'Service/CreateServiceRule',
  UPDATE_SERVICE_RULE: 'Service/UpdateServiceRule',
  ASSIGN_SERVICE_RULES: 'Service/AssignServiceRule',
  GET_SERVICE_LIST_BY_RULE_ID: 'Service/GetServicesByServiceRuleId?serviceRuleId=',


  //ServiceRulesMin
  GET_MIN_SERVICE_RULES: '/Service/GetClinicMinServiceRules',
  DELETE_MIN_SERVICE_RULE: '/Service/DeleteMinServiceRule?serviceRuleId=',
  CREATE_MIN_SERVICE_RULE: '/Service/CreateMinServiceRule',
  UPDATE_MIN_SERVICE_RULE: '/Service/UpdateMinServiceRule',
  ASSIGN_MIN_SERVICE_RULES: '/Service/AssignMinServiceRule',
  GET_SERVICE_LIST_BY_MIN_RULE_ID: '/Service/GetServicesByMinServiceRuleId?serviceRuleId=',



  DELETE_STAFF_PLAN_TEMPLATE: "/Clinic/DeleteStaffPlanTemplate",
  REMOVE_CLINIC_DOC_TAG: "/Clinic/DeleteClinicDocumentTag?id=",
  ASSIGN_TAG_TO_CLINIC_DOC: "/Clinic/AssignTagToClinicDocument",
  GET_STAFF_PLAN_DOC_TEMPLATE_SETTINGS: "/Clinic/GetStaffPlanDocumentTemplatesSettings",
  ADD_UPDATE_COMMENT: "/Client/AddUpdateClientComment",
  INSERT_AI_GENERATED_TREATMENT_PLAN: "/TreatmentPlanGenerator/InsertAiTreatmentPlan",
  DELETE_AI_GENERATED_TREATMENT_PLAN: "/TreatmentPlanGenerator/DeleteTreatmentPlanDraft",
  GET_STAFF_S3_SIGNED_URL: "/Staff/GetStaffS3SignedUrl",
  DELETE_STAFF_PROFILE_IMAGE: "/Staff/DeleteStaffProfileImage?staffId=",
  GET_STAFF_S3_DOCUMENTURL_BY_ID: "/Staff/GetStaffS3DocumentUrlById?id=",
  GET_CLIENT_S3_SIGNED_URL: "/Client/GetClientS3SignedUrl",
  GET_CLIENT_S3_DOC_URL_BY_ID: "/Client/GetClientS3DocumentUrlById?id=",
  GET_CLINIC_S3_SIGNED_URL: "/Clinic/GetClinicDocumentS3SignedUrl",
  GET_ALL_STAFF_DOCUMENT_TEMPLATES_WITH_SETTINGS: "/Clinic/GetAllStaffPlanTemplatesWithSettings",
  GET_GOAL_BY_ID: "/StaffPlanDocument/GetStaffGoalById?id=",
  GET_STAFF_GOALS: "/StaffPlanDocument/GetStaffGoals?staffId=",
  DELETE_STAFF_GOAL: "/StaffPlanDocument/DeleteStaffGoal?id=",
  INSERT_STAFF_GOAL: "/StaffPlanDocument/InsertStaffGoals",
  UPDATE_STAFF_GOAL: "/StaffPlanDocument/UpdateStaffGoals",
  GET_CLIENT_SECONDARY_INSURANCE_BY_DOS: "/Insurance/GetClientInsuranceByDos",
  GET_DOSESPOT_MESSAGE_COUNT: "/Eprescription/GetNotificationCount",
  GET_DOSEPOT_ENPRESCRIPTION_MEDICATION: "/Eprescription/GetClientMedications?clientId=",
  GET_DOSEPOT_ENPRESCRIPTION_ALLERGIES: "/Eprescription/GetClientAllergies?clientId=",
  GET_DOSEPOT_ENPRESCRIPTION_PHARMACIES: "/Eprescription/GetClientPharmacies?clientId=",

  // ----------------------PayRates--------------------------
  INSERT_STAFF_PAY_RATES: "/Staff/InsertStaffRates",
  GET_CLINIC_STAFF_RATES: "/Staff/GetClinicStaffRates?staffId=",
  DELETE_STAFF_RATES: "/Staff/DeleteStaffRates?id=",
  UPDATE_STAFF_RATES: "/Staff/UpdateStaffRates",
  FETCH_835_BATCH_COUNTS: "/Billing/Get835BillingRemittanceTotals",
  FETCH_835_CLAIM_COUNTS: "/Billing/Get835BillingClaimsTotals",
  FETCH_835_CLAIMS: "/Billing/Get835BillingClaims",
  GET_835_PAYERS: "/Common/Get835Payers",
  GET_COMPLIANCE_FILTERED_LIST: "/Clinic/GetComplianceReportFilterList",
  GET_CLIENT_S3_DOC_ATTACHMENT_URL_BY_ID: "/Client/GetDocumentAttachmentS3UrlById?id=",



  GET_DOC_IDEMPOTENCY_TOKEN: "/Document/GetDocumentIdempotencyToken",
  ASSIGN_SIPERVISOR: "/Document/AssignSupervisor",
  GET_DOCUMENT_TREATMENT_PLAN: "/Document/GetDocumentTreatmentPlan?documentId=",
  FETCH_837_BATCH_COUNTS: "/Billing/Get837BillingTransCounts",
  MARK_CLAIM_AS_RESOLVED: "/Billing/MarkClaimAsResolved",
  MARK_BATCH_AS_RESOLVED: "/Billing/MarkBillingBatchAsResolved",
  MARK_CLAIM_AS_ARCHIVED: "/Billing/Archive835Claims?status=",
  MARK_BATCH_AS_ARCHIVE: "/Billing/ArchiveBatchById",
  GET_CLAIM_FILING_INDIACATOR_CODES: "/Billing/GetClaimFilingIndicators",
  REACTIVATE_SITE: "/Site/ReactivateSite?id=",
  GET_AI_GENERATED_TREATMENT_PLAN: "/TreatmentPlanGenerator/GetAIGeneratedTreatmentPlan?clientId=",
  CHECK_FOR_ACTIVE_AI_REQUEST: "/TreatmentPlanGenerator/CheckForActiveRequestForTpPlanExistForClient?clientId=",
  GENERATE_TREATMENT_PLAN: "/TreatmentPlanGenerator/GenerateTreatmentPlan",
  GET_INACTIVE_CLINIC_SITES: "/Site/GetInactiveClinicSites",
  GET_CLIENT_BY_STATUS: "/Dashboard/GetClientsByStatuses",
  UPDATE_USER_SEATS: "/Staff/UpdateUserSeats",
  GET_CLIENTS_AUTHORIZATIONS: "/Client/GetAuthByClientIds",

  //Assign Notification SettingsToStaffOrRole
  ASSIGN_NOTIFICATION_SETTINGS_TO_STAFF_OR_ROLE: "/Notification/AssignNotificationSettingsToStaffOrRole",
  GET_ASSIGNED_STAFF_LIST_NOTIFICATION_SETTINGS: "/Notification/GetAssignedStaffListNotificationSettings?alertType=",
  INSERT_UPDATE_STAFF_PLAN_TEMPLATE_SETTINGS: "/Clinic/InsertUpdateStaffPlanTemplatesSettings",
  ADD_BILLING_COMMENT: "/Document/AddDocumentBillingComments",

  GET_UPCOMING_APPOINTMENTS: "/Dashboard/GetUpComingAppointment",
  GET_UPCOMING_COMPLIANCE_COUNT: "/Clinic/GetExpiringCompliancesClientCount",
  GET_UPCOMING_COMPLIANCE: "/Clinic/GetExpiringCompliancesClientReport",

  GET_SUPER_BILL: "/Billing/GetSuperBill?documentId=",

  /**** Client Ledger ****/

  INSERT_CLIENT_LEDGER: "/Client/InsertClientLedger",
  GET_CLIENT_LEDGER_BY_CLIENT_ID: "/Client/GetClientLedgerByClientId",
  DELETE_CLIENT_LEDGER_BY_ID: "/Client/DeleteClientLedgerById?id=",
  GET_CLIENT_LEDGER_BY_ID: "/Client/GetClientLedgerById?id=",
  UPDATE_CLIENT_LEDGER: "/Client/UpdateClientLedger",
  GET_PAYMENT_TYPES_CLIENT_LEDGER: "/Client/GetPaymentTypes?isActive=",
  GET_CLIENT_LEDGER_CURRENT_BALANCE: "/Client/GetClientLedgerCurrentBalance",

  INSERT_STAFF_CREDENTIAL_INFO: "/Staff/InsertStaffCredential",
  DELETE_STAFF_CREDENTIAL: "/Staff/DeleteStaffCredential?staffCredentialId=",
  GET_STAFF_CREDENTIAL_LIST: "/Staff/GetStaffCredentialByStaffId?staffId=",
  GET_STAFF_CREDENTIAL_BY_ID: "/Staff/GetStaffCredentialById?staffCredentialId=",
  UPDATE_STAFF_CREDENTIAL: "/Staff/UpdateStaffCredential",
  GET_UNREAD_COUNT: '/MessageCenter/GetUnreadCount',

  ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF: "/Staff/AssignAdditionalClientsToStaff",
  ASSIGN_STAFF_TO_SITE: "/Staff/AssignStaffToSite",
  GET_ATTACHED_DOCS_FOR_COMPLIANCE: "/Client/GetAllComplianceLinkedDocuments?",
  GET_ADJ_REASON_CODES: "/Billing/GetAdjustmentReasonCodes",
  EXPORT_TO_EXCEL_EXPRINING_COMPLIANCE_CLIENTS: "/Clinic/ExportToExcelExpiringComplianceClients",
  EXPORT_TO_EXCEL_AUTH_REMAINING_UNIT: "/Dashboard/ExportToExcelAuthRemainingUnit",

  //Clinic Reminders
  GET_CLINIC_REMINDER_SETTINGS: "/AppointmentReminder/GetAppointmentReminderSettings",
  INSERT_REMINDER_SETTINGS: "/AppointmentReminder/InsertAppointmentReminderSettings",
  INSERT_EMAIL_REMINDER_SETTINGS: "/AppointmentReminder/InsertEmailReminderSettings",
  INSERT_PHONE_REMINDER_SETTINGS: "/AppointmentReminder/InsertPhoneReminderSettings",
  INSERT_TEXT_REMINDER_SETTINGS: "/AppointmentReminder/InsertTextReminderSettings",
  GET_REMINDER_AVAILABLE_NUMBER: "/AppointmentReminder/GetAvailablePhoneNumber?",
  GET_AUDIT_LOGS: "/AppointmentReminder/GetAppointmentAuditLog?",

  //Client Account Acccess Management
  GET_CLIENT_PORTAL_ACCOUNT_ACCESS_LIST: "/Client/GetClientPortalAccessAccounts",
  CREATE_ACCOUNT_FOR_CLIENT_PORTAL_ACCESS: "/Client/CreateClientPortalAccessAccount",
  UPDATE_ACCOUNT_FOR_CLIENT_PORTAL_ACCESS: "/Client/UpdateClientPortalAccessAccount",
  GET_CLIENT_PORTAL_ACCOUNT_ACCESS_DETAIL: "/Client/GetClientPortalAccessAccountById?id=",
  DELETE_CLIENT_PORTAL_ACCOUNT_ACCESS: "/Client/DeleteClientPortalAccessAccount?id=",
  GET_CLIENT_PORTAL_ACCOUNT_ACCESS_DETAILS: "/Client/GetClientPortalAccessAccountByClient?clientId=",
  RESET_ACCESS_PWD: "/Client/ResetClientPortalAccessAccountPassword",


  GET_EBP_CODES: "/Common/GetEbpCodes",

  //client medication 

  INSERT_CLIENT_MEDICATION: "/ClientMedication/InsertClientMedication",
  UPDATE_CLIENT_MEDICATION: "/ClientMedication/UpdateClientMedication",
  DELETE_CLIENT_MEDICATION: "/ClientMedication/DeleteClientMedication",
  GET_CLIENT_MEDICATION_BY_CLIENT_ID: "/ClientMedication/GetClientMedicationByClientId",
  GET_CLIENT_MEDICATION_BY_ID: "/ClientMedication/GetClientMedicationById",

  EXPORT_CASELOAD_REPORT_TO_EXCEL: "/staff/ExportCaseloadReportIntoExcel",


  INSERT_UPDATE_SOCIAL_DETERMINANTS: "/Client/InsertOrUpdateClientSocialDeterminants",
  SAVE_SOCIAL_DETERMINANTS_RESPONSE: "/Client/GetClientSocialDeterminants",
  VIEW_SOCIAL_DETERMINANTS_HISTORY: "/Client/GetClientSocialDeterminantsHistory?clientId=",

  UPDATE_CUSTOM_ROLE_NAME: "/Clinic/UpdateCustomRoleName",

  GET_CLIENT_MESSAGE_LABEL: "/MessageCenter/GetClientMessageLabel",
  INSERT_CLIENT_MESSAGE_LABEL: "/MessageCenter/InsertClientMessageLabel",
  UPDATE_CLIENT_MESSAGE_LABEL: "/MessageCenter/UpdateClientMessageLabel",
  DELETE_CLIENT_MESSAGE_LABEL: "/MessageCenter/DeleteClientMessageLabel?id=",
  MOVE_CLIENT_MESSAGE_TO_LEVEL: '/MessageCenter/MoveClientMessageToCustomLabel',

  GET_CLIENT_COLLATERAL_CONTACT_BY_ID: "/Client/GetClientCollateralContactById",
  GET_CONTACT_TYPE_LIST: "/Common/GetCollateralContactsTypes",
  GET_CLIENT_COLLATERAL_CONTACT: "/Client/GetClientCollateralContactsByClientId",
  SAVE_CLIENT_COLLATERAL_CONTACT_INFO: "/Client/InsertClientCollateralContact",
  UPDATE_CLIENT_COLLATERAL_CONTACT_INFO: "/Client/UpdateClientCollateralContact",
  DELETE_CLIENT_COLLATERAL_CONTACT: "/Client/DeleteClientCollateralContact",

  GET_COMPLIANCE_AUDIT_HISTORY: "/Client/GetComplianceAuditHistory",

  REMOVE_DOC_UNITS: "/Document/RemoveDocumentUnits",
  RECALCULATE_DOC_UNITS: "/Document/RecalculateDocumentAuthorization",

  UPDATE_TRANSSRVLINE_BILLING_STATUS: "/Billing/UpdateTransSrvLineBillingStatus",

  // staff comment api
  SAVE_UPDATE_STAFF_COMMENT: "/Staff/UpdateStaffComment",
  GET_STAFF_COMMENT: "/Staff/GetStaffComment?staffId=",

  DELETE_REFERRAL_SOURCE: "/Referral/DeleteClientReferralSource",
  DELETE_REFERRAL_PROVIDER: "/Referral/DeleteClientReferralProvider",

  GET_CMS_1500_BY_CLAIM_ID: "/Billing/GetCMS1500ByClaimId",
  GET_CMS_1500_FOR_BATCH: "/Billing/GetCMS1500ForBatch",

  GET_CLIENTS_WITH_STAFF_CASELOADS: "/Staff/GetMultiStaffCaseload",

  GET_SERVICE_MIN_REPORT_BY_CLIENT_ID: "/Client/GetServiceMinReportByClientId",

  /* staff reports */
  GET_STAFF_REPORTS: "/Reporting/GetStaffReports",
  GET_ALL_STAFF_REPORTS: "/Reporting/GetAllReports",
  POST_STAFF_REPORTS: "/Reporting/AssignReportsToStaff",

  /* report manager */
  GET_REPORT_TYPES: "/Reporting/GetReportTypes",
  GET_REPORTS_BY_REPORT_TYPE: "/Reporting/GetReportsByReportType",
  GET_REPORT_COLUMNS: "/Reporting/GetReportColumns",
  GET_STAFF_REPORT_COLUMN_PREFERENCES: "/Reporting/GetStaffReportColumnPreferences",
  ASSIGN_REPORTS_TO_ROLE: "/Reporting/AssignReportsToRole",
  GET_ROLE_REPORTS: "/Reporting/GetRoleReports",
  INSERT_STAFF_REPORT_COLUMN_PREFERENCES: "/Reporting/InsertStaffReportColumnPreferences",
  GET_ASSIGNED_STAFF_OF_REPORT: "/Reporting/GetAssignedStaffofReport",
  ASSIGN_REPORT_TO_MULTIPLE_STAFF: "/Reporting/AssignReportToMultipleStaff",
  GET_STAFF_REPORTS_WITH_TYPES: "/Reporting/GetStaffReportsWithTypes",
  GET_CLIENTS_FOR_CASELOAD_MAP: "/Client/GetClientsForCaseloadMap",
  GET_CLIENT_GENERAL_REPORT: "/Reporting/GetClientGeneralReport",

  /* Announcements */
  GET_ANNOUNCEMENTS: "/Bulletin/GetBulletins",
  DELETE_ANNOUNCEMENTS: "/Bulletin/DeleteBulletin?id=",
  GET_ANNOUNCEMENTS_BY_ID: "/Bulletin/GetBulletinById?id=",
  SAVE_ANNOUNCEMENTS_LIST: "/Bulletin/InsertBulletin",
  UPDATE_ANNOUNCEMENTS_LIST: "/Bulletin/UpdateBulletin",

  /* mileage */
  GET_MILEAGE_PAY_RATE_BY_STAFF_ID: "/Staff/GetMileagePayRateByStaffId",
  UPDATE_AUTH_BATCH_END_DATE: "/Client/UpdateAuthBatchEndDate",
  REACTIVATE_TREATMENT_PLAN: "/Client/ReactivateTreatmentPlan",


  GET_CUSTOMER_INVOICES: "/SubscriptionManager/GetCustomerInvoices",
  GET_CUSTOMER_PAYMENT_METHOD: "/SubscriptionManager/GetCustomerPaymentMethods",
  GET_CUSTOMER_SUBSCRIPTIONS: "/SubscriptionManager/GetCustomerSubscriptions",
  ATTACH_PAYMENT_METHOD: "/SubscriptionManager/AttachPaymentMethod?stripePaymentMethodId=",
  GET_CUSTOMER: "/SubscriptionManager/GetStripeCustomer",
  UPDATE_DEFAULT_METHOD: "/SubscriptionManager/UpdateCustomerDefaultPaymentMethod?stripePaymentMethodId=",


  GET_837_CLAIM_BY_ID: "/Billing/Get837ClaimById",
  SAVE_REMITTANCE_DATA: "/Billing/InsertRemittance",
  GET_CLIENTS_MEDICATION: "/Client/GetClientsMedication",
  GET_FULL_REMITTANCE_BY_ID: "/Billing/GetFullRemittanceById",

  //export to excel Contact_NOTES
  GET_EXPORT_TO_EXCEL_CONTACT_NOTES:"/Client/ExportToExcelContactNotesByClientId",

  //clientDocZip
  GET_CLIENT_S3_SIGNED_PRINTED_DOC_URL:"/Document/GetClientS3SignedPrintedDocumentUrl",
  REACTIVATE_DELETE_PRINTED_DOC:"/Document/DeleteOrReactivatePrintedDocument",
  GET_CLIENTS_PRINTED_DOCUMENT:"/Document/GetClientPrintedDocuments",
  GET_CLINIC_S3_PRINTED_DOCUMENT_URL_BYID:"/Document/GetClientS3PrintedDocumentUrlById?id=",

  

  GET_STAFF_SERVICE_REPORT_BY_STAFF_ID:"/Staff/GetStaffServiceRuleReport",
  GET_SERVICE_RULE_EXPECTED_USAGE:"/Service/GetServiceRuleExpectedUsages",

  GET_CLIENTS_PRINTED_DOCUMENT_BY_DOCUMENT_ID:"/Document/GetClientPrintedDocumentByDocumentId",

  GET_CLIENTS_FILE_FOR_TP:"/Client/GetClientPrintedTreatmentPlanById",
  GET_S3_URL_TO_UPLOAD_TREATMENT_PLAN:"/Client/GetClientS3SignedPrintedTreatmentPlanUrl",
  UPDATE_FILE_CREATED_FOR_TP:"/Client/DeleteOrReactivatePrintedTreatmentPlan",
  GET_CLIENTS_FILES_FOR_TP:"/Client/GetClientPrintedTreatmentPlans",
  GET_CLINIC_S3_PRINTED_TP_URL_BY_ID:"/Client/GetClientS3PrintedTreatmentPlanUrlById?id=",
  
  GET_GENDER_IDENTITY: "/Common/GetGenderIdentity",
  GET_SEXUAL_ORIENTATION: "/Common/GetSexualOrientation",

  GET_CLIENT_MEDICAL_HISTORY: "/Client/GetClientMedicalHistory",
  INSERT_CLIENT_MEDICAL_HISTORY:"/Client/InsertClientMedicalHistory",
  UPDATE_CLIENT_MEDICAL_HISTORY:"/Client/UpdateClientMedicalHistory",
  DELETE_CLIENT_MEDICAL_HISTORY:"/Client/DeleteClientMedicalHistory",

  GET_FAMILY_MEDICAL_HISTORY:"/Client/GetClientFamilyMedicalHistory",
  INSERT_FAMILY_MEDICAL_HISTORY:"/Client/InsertFamilyMedicalHistory",
  UPDATE_FAMILY_MEDICAL_HISTORY:"/Client/UpdateFamilyMedicalHistory",
  DELETE_FAMILY_MEDICAL_HISTORY:"/Client/DeleteClientFamilyMedicalHistory"
};



