import { BillingCountResultData, ServiceUnitRenderResultData,TrainingLinkData,UpcomingAppointmentsData,UpcomingComplianceCountData,ServiceRuleExpectedUsageDataModal} from "src/dataModels/dashboardResponseModel";
import { SAVE_BILLING_COUNTS, SAVE_BILLING_DATE, SAVE_CLIENT_BY_GENDER_COUNT, SAVE_CLIENT_COUNT, SAVE_CLIENT_DIAGNOSIS_COUNT, SAVE_CLOCK_STATUS, SAVE_SERVICE_UNITS_RENDER, SAVE_TASK_FILTER, SAVE_TIME_CLOCK_ARCHIVE, SAVE_TIME_CLOCK_BY_ID, SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE ,SAVE_TRAINING_LINKS,SAVE_UPCOMING_APPOINTMENTS,SAVE_UPCOMING_COMPLIANCE_COUNT_DATA, TIME_CLOCK_FILTER, ADMIN_BOARD_FILTERS, SAVE_INSURANCE_TYPES, CUSTOM_ROLE_CHANGED, SAVE_CLIENT_TAB_ADMIN_BOARD_FILTERS, SET_DEFAULT_ROLE_TO_CUSTOM, SAVE_AUTH_PAGE,SAVE_CURRENT_TASK,CURRENT_TASK_LODING_STATE,SAVE_ANNOUNCEMENTS,SAVE_ANNOUNCEMENTS_BY_ID,GET_ALL_ANNOUNCEMENTS,ANNOUNCEMENTS_REFRESH_STATE, SAVE_DISCHARGE_REASONS,SAVE_SERVICE_RULE_EXPECTED_USAGE} from "../actions/types";

const initialState = {
    serviceUnitRender: {} as ServiceUnitRenderResultData,
    billingCount: {} as BillingCountResultData,
    timeclockStatus: {},
    clientCountByGender: {},
    staffClockTimeHistory: [],
    trainingLink:[] as TrainingLinkData[],
    upcomingAppointments:[] as UpcomingAppointmentsData[],
    upcomingComplianceCount : [] as UpcomingComplianceCountData[],
    timeClockFilters: {},
    adminBoardFilters: {},
    respInsuranceTypes: {},
    customRoleChanged: false,
    clientTabAdminBoardFilters: {},
    defaultRoleCustom: false,
    savedAuthPage: null,
    currentTasksResp:[],
    currentTaskslodingState:false,
    announcementsByIdResp:{},
    announcementsResp:[],
    announcementRefreshState:false, 
    dischargeReasons: [],
    serviceRuleExpectedURespList:[] as ServiceRuleExpectedUsageDataModal[]
}

export const DashboardReducer = (state = initialState, action: any) => {
    const oldState = { ...state };
    const { type, payload } = action;

    switch (type) {
        case SAVE_CLIENT_COUNT:
            return {
                ...oldState,
                clientCount: payload,
            };
        case SAVE_CLIENT_BY_GENDER_COUNT:
          
            return {
                ...oldState,
                clientCountByGender: payload,
            };
        case SAVE_CLIENT_DIAGNOSIS_COUNT:
            return {
                ...oldState,
                clientDiagnosisCount: payload,
            };
        case SAVE_SERVICE_UNITS_RENDER:
            return {
                ...oldState,
                serviceUnitRender: payload
            }

        case SAVE_BILLING_COUNTS:
            return {
                ...oldState,
                billingCount: payload
            }

        case SAVE_BILLING_DATE:
            return {
                ...oldState,
                billingDate: payload
            }
            case SAVE_CLIENT_TAB_ADMIN_BOARD_FILTERS:
                return {
                    ...oldState,
                    clientTabAdminBoardFilters: payload
                }
        case SAVE_TASK_FILTER:
            return {
                ...oldState,
                taskFilterItems: payload
            }

        case SAVE_CLOCK_STATUS:
            return {
                ...oldState,
                timeclockStatus: payload
            }
        case SAVE_TIME_CLOCK_BY_ID:
            return {
                ...oldState,
                timeClockById: payload
            }
        case SAVE_UPDATE_STAFF_TIME_CLOCK_RESPONSE:
            return {
                ...oldState,
                updateClockTimeResponse: payload
            }
        case SAVE_TIME_CLOCK_ARCHIVE: 
            return {
                ...oldState,
                staffClockTimeHistory: payload
            }
        case SAVE_TRAINING_LINKS: 
            return {
                ...oldState,
                trainingLink: payload
            }  
        case SAVE_UPCOMING_APPOINTMENTS: 
            return {
                ...oldState,
                upcomingAppointments: payload
            } 
        case SAVE_UPCOMING_COMPLIANCE_COUNT_DATA: 
            return {
                ...oldState,
                upcomingComplianceCount: payload
            }    
        case TIME_CLOCK_FILTER:
            return {
                ...oldState,
                timeClockFilters: payload
            } 
        case ADMIN_BOARD_FILTERS:
            return {
                ...oldState,
                adminBoardFilters: payload
            } 
        
        case SAVE_INSURANCE_TYPES:
            return {
            ...oldState,
            respInsuranceTypes: payload
        } 
        case CUSTOM_ROLE_CHANGED:
            return {
            ...oldState,
            customRoleChanged: payload
        } 
        case SET_DEFAULT_ROLE_TO_CUSTOM:
            return {
            ...oldState,
            defaultRoleCustom: payload
        } 
        case SAVE_AUTH_PAGE: 
            return {
                ...oldState,
                savedAuthPage: payload
            }
        case SAVE_CURRENT_TASK: 
            return {
                ...oldState,
                currentTasksResp: payload
            }
        case CURRENT_TASK_LODING_STATE:
            return {
                ...oldState,
                currentTaskslodingState: payload
            }
        case SAVE_ANNOUNCEMENTS:
            return {
                ...oldState,
                announcementsResp: payload
            }
        case SAVE_ANNOUNCEMENTS_BY_ID:
            return {
                ...oldState,
                announcementsByIdResp: payload
                }
        case ANNOUNCEMENTS_REFRESH_STATE:
            return {
                ...oldState,
                announcementRefreshState: payload
                }
        case SAVE_DISCHARGE_REASONS:
            return {
                ...oldState,
                dischargeReasons: payload
            }
        case SAVE_SERVICE_RULE_EXPECTED_USAGE:
            return {
                ...oldState,
                serviceRuleExpectedURespList: payload
            }

                
    }
    return oldState;
}

export const allAnnouncementShow = (state = {showAll:false}, action) => {

    switch (action.type) {
      case GET_ALL_ANNOUNCEMENTS:
        return action.payload;
  
    default:
      return state;
    }
  
  };