import React, { useEffect } from 'react';
import moment from "moment";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { forwardRef, useRef, useImperativeHandle } from "react"
import { getTimeClockArchiveById } from 'src/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";

const HistoryModel = (props: HistoryModelProps) => {
  const {
    title,
    message,
    onClose,
    staffIdTimeClock,
    ...rest
  } = props;
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTimeClockArchiveById(staffIdTimeClock))
  }, [])

  const state: any = useSelector(states => {
    return states;
  });
  const { staffClockTimeHistory } = state['DashboardReducer'];

  return (
    <Dialog className='blue_theme'
      onClose={onClose} title={props.title}>
      <p
        style={{
          margin: "25px",
          width: '80vw',
          height: '70vh'
        }}
      >
        <div className="table-responsive blue_theme table_view_dt mt-3 blueThemeTable">
        <table className="table striped-table">
        <thead>
          <tr>
            <th scope="col" className='cursor-defalt'>Staff</th>
            <th scope="col" className='cursor-defalt'>Time In</th>
            <th scope="col" className='cursor-defalt'>Time Out</th>
            <th scope="col" className='cursor-defalt'>Hours Worked</th>
            <th scope="col" className='cursor-defalt'>Approved</th>
            <th scope="col" className='cursor-defalt'>Reason</th>
            <th scope="col" className='cursor-defalt'>Description</th>
          </tr>
        </thead>
        <tbody>
          {staffClockTimeHistory.map((item) => (
            <tr key={item.id}>
              <td className='cursor-defalt'>{item.staff}</td>
              <td className='cursor-defalt'>{item.clockIn ? moment.utc(item.clockIn).local().format("M/D/YYYY hh:mm A") : '-'}</td>
              <td className='cursor-defalt'>{item.clockOut ? moment.utc(item.clockOut).local().format("M/D/YYYY hh:mm A") : '-'}</td>
              <td className='cursor-defalt'>{item.durationTime ? item.durationTime : '0 m'}</td>
              <td>
                {item.isApproved === true ? (
                  <span className="fa fa-check-circle cursor-default f-18" style={{ color: "green" }}></span>
                ) : item.isApproved === false ? (
                  <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                ) : (
                  ''
                )}
              </td>
              <td>{item.reason}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </p>
    </Dialog>
  )
}

export default HistoryModel

export interface HistoryModelProps {
  title: string
  message: String
  staffIdTimeClock: any,
  onClose?: () => void | number;
}
