import React, { ComponentState, useEffect, useRef, useState } from "react";
import Loader from "../../../../control-components/loader/loader";

import { saveAs } from "@progress/kendo-file-saver";
import pako from "pako";
import { useSelector } from "react-redux";
import { QuestionnaireFields } from "src/dataModels/QuestionnaireFields";
import DateTimeHelper from "src/helper/date-time-helper";
import { renderErrors } from "src/helper/error-message-helper";
import { MaskFormatted } from "src/helper/mask-helper";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { showError } from "src/util/utility";
import { ClientService } from "../../../../services/clientService";
import MyComponent from "./questionnaire-module/patient-health-questionnaire";
import { styles } from "./style";



const PrintQuestionnaire = () => {
  const [loading, setLoading] = useState(false);
  const [questionsDetails, setQuestionDetails] = useState<any>();
  const [questionListById, setQuestionListById] = useState([]);
  const [queData, setQueData] = useState<QuestionnaireFields>();
  const clientDetail = useSelector((state: ComponentState) => state.clientDetails);
  const _elementRef = useRef<HTMLDivElement | null>(null);
  const [isComponentRendered, setIsComponentRendered] = useState(false);
  const currentInsurance = useSelector(
    (state: ComponentState) => state.currentInsuranceDetails
  );
  const primaryDx = useSelector(
    (state: ComponentState) => state.PrimaryDX
  );
  const clinicDetails = useSelector((state: ComponentState) => state.getClinicDetails);
  let minRankNumber = Infinity;
  let minDiagnoseName = '';
  primaryDx?.forEach(({ rankNumber, icd10 }) => {
    if (rankNumber < minRankNumber) {
      minRankNumber = rankNumber;
      minDiagnoseName = icd10;
    }
  });

  let newObj = clinicDetails?.zip
  let intZip = newObj !== undefined && newObj.slice(0, 5)
  let lastZip = newObj !== undefined && newObj.slice(5)
  let Phone = MaskFormatted(
    clinicDetails ? clinicDetails?.phone : "",
    "(999) 999-9999"
  );

  useEffect(() => {
    const queDataSet = localStorage.getItem('print-que-state')
    if (queDataSet) {
      setQueData(JSON.parse(queDataSet))
      getQuestionsById(JSON.parse(queDataSet).id);
      const dropDownValue = {
        fullName: JSON.parse(queDataSet)?.questionnaire,
        id: JSON.parse(queDataSet)?.questionnaireId,
      };
      setQuestionDetails(dropDownValue);
    }
  }, []);




  const getQuestionsById = async (queIds) => {
    await ClientService.getQuestionsById(queIds)
      .then((result) => {
        let questionListById1 = result?.resultData;
        setQuestionListById(JSON.parse(questionListById1?.data));
      })
      .catch((error) => {

        renderErrors(error.message);
      });
  };

  React.useEffect(() => {

    if (_elementRef.current && isComponentRendered) {
      // console.log("questionListById", questionListById)
      setTimeout(() => {
        handleSelect();
      }, 1 * 1000)
    }
  }, [_elementRef, isComponentRendered]);

  const handleSelect = async () => {
    try {
      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join("");
          } catch (e) {

          }
          return "";
        })
        .join("\n");

      const element = _elementRef.current;
      const metaD = {
        clientName: `${clientDetail?.lName}, ${clientDetail?.fName}`,
        dob: DateTimeHelper.formatDatePickerString(clientDetail?.dob),
        insurance: (currentInsurance?.insuranceName && currentInsurance?.insuranceName.split('-')[1])
          ? `${currentInsurance?.insuranceName.split('-')[1]} - ${currentInsurance?.policyNumber}` : '',
      }
      const queDataSet: QuestionnaireFields = JSON.parse(localStorage.getItem('print-que-state')!)
      // console.log(_elementRef.current.outerHTML);

      const formattedElement = {
        html: element?.outerHTML,
        title: "Document Questionnaire",
        author: "Notenetic Team",
        styles,
        metaData: { ...queDataSet, ...metaD }
      };

      const url = API_ENDPOINTS.PUPPETEER_BASE_URL + '/generate-pdf/questionnaire';
      const body = JSON.stringify({ elements: [formattedElement] });
      const compressedBody = pako.gzip(body);

      let retry = 1;
      let maxRetry = 3;
      while (retry <= maxRetry) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/octet-stream",
          },
          body: compressedBody,
        });
        if (!response.ok) {
          if (retry == maxRetry) {

            const errorMsg = `Error: ${response?.statusText || response?.status}`;
            showError(errorMsg);
            console.error("Error generating PDF: Network response was not ok", response);
            break;
          }
        } else {
          const blob = await response.blob();
          saveAs(blob, "Questionnaire.pdf");
          localStorage.removeItem('print-que-state')
          window.close()
          break;
        }
        retry++;
      }
    } catch (error) {
      const errorMsg = `Error: ${error}`;
      showError(errorMsg);
      console.error("Error generating PDF:", error);
    }
  };

  const clientHeaderInfo = () => {
    return (
      <div className="grid-template-pdf new-grid-template-pdf grid-template-pdf-head ">
        <div className="col-12" >
          <span style={{ color: "#000", fontSize: "22px", marginLeft: "2px" }}>
            <span style={{ color: "#000", fontSize: "22px", }}>{clientDetail ? clientDetail?.lName : ""}, {clientDetail ? clientDetail?.fName : ""}</span>
          </span>
          <ul className="list-unstyled list-icons-pdf new-list-icons-pdf" style={{ marginBottom: "0px", fontSize: "20px" }}>
            <li className="client-name-head" style={{ color: "#000", padding: "0" }}>
              DOB:
              <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                {DateTimeHelper.formatDatePickerString(clientDetail?.dob)} </span>
            </li>
            <li className="client-name-head" style={{ color: "#000", padding: "0" }}>
              Ins:
              <span style={{ color: "#4a4a4b", fontFamily: "Manrope !important", marginLeft: "2px" }}>
                {currentInsurance?.insuranceName && currentInsurance?.policyNumber
                  ? `${currentInsurance.insuranceName.split('-')[1]} - ${currentInsurance.policyNumber}` : 'NA'}
              </span>
            </li>
            <li className="client-name-head" style={{ color: "#000", padding: "0" }}>
              Primary Dx:
              <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                {minDiagnoseName} </span>
            </li>
          </ul>
        </div>

        <div className="col-12" style={{ paddingTop: "5px" }}>
          <ul className="list-unstyled list-icons-pdf new-list-icons-pdf" style={{ marginBottom: "0px" }}>
            <li className="client-name-head" style={{ color: "#000", padding: "0" }}>
              Program Start Date:
              <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", marginLeft: "2px", }}>
                {DateTimeHelper.formatDatePickerString(clientDetail?.dateStart)} </span>


            </li>
            <li className="client-name-head" style={{ color: "#000", padding: "0" }}>

              <span style={{
                color: "#000", fontSize: "20px", fontWeight: "600",
                fontFamily: "Manrope  !important"
              }}>{clientDetail?.clinicName}<br />
                <span style={{ color: "#4a4a4b", fontFamily: "Manrope  !important", }}>
                  {(clinicDetails?.address).trim()}<br />
                  {`${clinicDetails?.city}, ${clinicDetails?.state} ${lastZip.length == 0 ? (intZip + lastZip) : (intZip + "-" + lastZip)}`} <br />
                  {Phone}
                </span></span>

            </li>
            <li style={{ color: "#000", padding: "0" }}></li>

          </ul>
        </div>
      </div>

    )

  }


  return (
    <div >
      <style> {styles}</style>
      {(loading === false && questionListById.length > 0 && (questionsDetails?.enumId || JSON.stringify(queData?.enumId))) ?

        <div
          ref={(div: any) => {
            _elementRef.current = div;
            setIsComponentRendered(true);
          }}
        >


          {clientHeaderInfo()}
          <MyComponent
            key={queData?.id}
            componentType={
              questionsDetails?.enumId || JSON.stringify(queData?.enumId)
            }
            isPrint={true}
            questionId={questionsDetails?.id}
            questionListById={questionListById}
          />
        </div>
        : <Loader />}
    </div>
    // </div>
  );
};

export default PrintQuestionnaire;
