import { getStaffCredentialListResultData,StaffServiceReportResponseData } from "src/dataModels/staffResponsesModels";
import {
  SELECTED_STAFF_ID,
  GET_STAFF,
  GET_MARITIALS_STATUS,
  STAFF_LOGIN_DETAIL,
  GET_GENDER,
  GET_STAFF_DETAILS,
  GET_STAFF_PROFILE_IMG,
  GET_ROLE_PERMISSIONS,
  ALL_STAFF_AVAILABLE,
  GET_DOCUMENT_FILTER,
  GET_CLIENT_DOCUMENT_FILTER,
  GET_DOC_BILLING_FILTER,
  STAFF_ONLINE_STATUS,
  GET_BILL_TRANSACTION_FILTER,
  GET_BILL_REMITTANCE,
  SELECTED_STAFF_FILTER,
  SAVE_ACTIVE_STAFF_SEATS,
  GET_BILL_CLAIM_VIEW,
  SAVE_AUTHORIZATION_FILTER,
  SAVE_STAFF_NOTIFICATION_SETTING,
  SAVE_STAFF_TASK_NOTIFICATION_SETTING,
  SAVE_STAFF_SCHEDULER_NOTIFICATION_SETTING,
  SAVE_CLIENT_AUTHORIZATION_FILTER,
  SAVE_STAFF_LIST,
  STAFF_NOTIFICATION_MEDIUM_SETTING,
  GET_SCHEDULER_FILTER,
  SAVE_STAFF_CREDENTIAL_LIST,
  SAVE_STAFF_AUDIT_LOG_LIST,
  SAVE_STAFF_AUDIT_LOG_META_DATA,
  STAFF_AUDIT_LOG_LIST_CHANGED,
  STAFF_AUDIT_FILTER,
  FETCH_STAFF_LIST_AGAIN,
  SAVE_EXPORT_CASELOAD_REPORT_TO_EXCEL,SAVE_STAFF_NEW_NOTIFICATION_SETTING,
  SAVE_STAFF_COMMENT,
  SAVE_STAFF_REPORTS,
  SAVE_ALL_STAFF_REPORTS,
  FETCH_STAFF_REPORTS,
  FETCH_ALL_STAFF_REPORTS,
  SAVE_REPORT_TYPES,
  SAVE_REPORTS_BY_REPORT_TYPE,
  FETCH_REPORT_TYPES,
  SAVE_REPORT_COLUMNS,
  SAVE_ROLE_REPORTS,
  SAVE_STAFF_REPORT_COLUMN_PREFERENCES,
  SAVE_ASSIGNED_STAFF_OF_REPORT,
  SAVE_STAFF_REPORTS_WITH_TYPES,
  SAVE_STAFF_SERVICE_REPORT_BY_STAFF_ID
} from "../actions/types";

const initialState = {
  gender: {},
  activeStaffSeats: null,
  staffTimeClockHistory: {},
  staffCredentialsList :[] as getStaffCredentialListResultData[],
  staffAuditLogList: [],
  staffAuditLogMetaData: {},
  staffAuditLogListChanged: true,
  staffAuditFilters: {}, 
  fetchStaffListAgain: false,
  excelRespCaseloadReport: {},
  staffCommentResp:null,
  allStaffReportsResp: [],
  staffReportsResp: [],
  fetchStaffReports: true,
  fetchAllStaffReports: true,
  reportTypesResp: [],
  reportsByTypeResp: [],
  fetchReportTypes: true,
  reportColumns: [],
  roleReports: [],
  staffReportColumnPrefResp: [],
  assignedStaffForReport: [],
  staffReportWithTypeResp: [],
  staffServiceReportResp:[] as StaffServiceReportResponseData[]
};

export const StaffReducer = (state = initialState, action: any) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case SAVE_ACTIVE_STAFF_SEATS:
      return {
        ...oldState,
        activeStaffSeats: payload,
      };
    case SAVE_STAFF_NOTIFICATION_SETTING:
      return {
        ...oldState,
        notificationvalue: payload,
      };
    case SAVE_STAFF_TASK_NOTIFICATION_SETTING:
        return {
          ...oldState,
          taskNotificationvalue: payload,
      };
      case SAVE_STAFF_SCHEDULER_NOTIFICATION_SETTING:
          return {
            ...oldState,
            schedulerNotificationvalue: payload,
        };
    case SAVE_STAFF_NEW_NOTIFICATION_SETTING:
      return {
        ...oldState,
        listStaffNewNotificationSetting: payload,
      };
    
    case SAVE_STAFF_LIST:
      return {
        ...oldState,
        staffsList: payload,
      };
      case STAFF_NOTIFICATION_MEDIUM_SETTING:
      return {
        ...oldState,
        notificationMediumValue: payload,
      };
      case SAVE_STAFF_CREDENTIAL_LIST:
      return {
        ...oldState,
        staffCredentialsList: payload,
      };
      case SAVE_STAFF_AUDIT_LOG_LIST:
        return {
          ...oldState,
          staffAuditLogList: payload,
        };
        case SAVE_STAFF_AUDIT_LOG_META_DATA:
          return {
            ...oldState,
            staffAuditLogMetaData: payload,
          };

          case STAFF_AUDIT_LOG_LIST_CHANGED:
            return {
              ...oldState,
              staffAuditLogListChanged: payload,
            };

            case STAFF_AUDIT_FILTER:
              return {
                ...oldState,
                staffAuditFilters: payload
              }

              case FETCH_STAFF_LIST_AGAIN:
                return {
                  ...oldState,
                  fetchStaffListAgain: payload
                }

                case SAVE_EXPORT_CASELOAD_REPORT_TO_EXCEL:
                  return {
                    ...oldState,
                    excelRespCaseloadReport: payload
                  }
                case SAVE_STAFF_COMMENT:
                  return {
                    ...oldState,
                    staffCommentResp: payload
                  }
                  case SAVE_STAFF_REPORTS:
                    return {
                      ...oldState,
                      staffReportsResp: payload
                    }
                case SAVE_ALL_STAFF_REPORTS:
                    return {
                      ...oldState,
                      allStaffReportsResp: payload
                    }
                    case FETCH_STAFF_REPORTS:
                      return {
                        ...oldState,
                        fetchStaffReports: payload
                      }
                  case FETCH_ALL_STAFF_REPORTS:
                      return {
                        ...oldState,
                        fetchAllStaffReports: payload
                      }
                      case SAVE_REPORT_TYPES:
                        return {
                          ...oldState,
                          reportTypesResp: payload
                        }
                        case SAVE_REPORTS_BY_REPORT_TYPE:
                          return {
                            ...oldState,
                            reportsByTypeResp: [
                              ...oldState.reportsByTypeResp,
                              ...payload.filter(newReport => 
                                !oldState.reportsByTypeResp.some((existingReport: any) => existingReport.id === newReport.id)
                              )
                            ]
                          };                        
                          case FETCH_REPORT_TYPES:
                            return {
                              ...oldState,
                              fetchReportTypes: payload
                            }
                          case SAVE_REPORT_COLUMNS:
                            return {
                              ...oldState,
                              reportColumns
                              : payload
                            }
                          case SAVE_ROLE_REPORTS:
                            return {
                              ...oldState,
                              roleReports
                              : payload
                            }
                            case SAVE_STAFF_REPORT_COLUMN_PREFERENCES:
                              return {
                                ...oldState,
                                staffReportColumnPrefResp
                                : payload
                              }
                              case SAVE_ASSIGNED_STAFF_OF_REPORT:
                                return {
                                  ...oldState,
                                  assignedStaffForReport
                                  : payload
                                }
                                
                                case SAVE_STAFF_REPORTS_WITH_TYPES:
                                  return {
                                    ...oldState,
                                    staffReportWithTypeResp
                                    : payload
                                  }
                                case SAVE_STAFF_SERVICE_REPORT_BY_STAFF_ID:
                                    return {
                                      ...oldState,
                                      staffServiceReportResp
                                      : payload
                                    }

  }
  return oldState;
};

export const selectedStaffIdReducer = (state = false, action) => {
  switch (action.type) {
    case SELECTED_STAFF_ID:
      return action.payload;
    default:
      return state;
  }
};

export const getStaffReducer = (state = false, action) => {
  switch (action.type) {
    case GET_STAFF:
      return action.payload;
    case STAFF_LOGIN_DETAIL:
      return action.payload;
    default:
      return state;
  }
};


export const getMaritialsStatusReducer = (state = false, action) => {
  switch (action.type) {
    case GET_MARITIALS_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export const getGenderReducer = (state = false, action) => {
  switch (action.type) {
    case GET_GENDER:
      return action.payload;
    default:
      return state;
  }
};

export const getStaffDetails = (state = false, action) => {
  switch (action.type) {
    case GET_STAFF_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
export const getStaffOnline = (state = false, action) => {
  switch (action.type) {
    case STAFF_ONLINE_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export const getStaffProfileImg = (state = false, action) => {
  switch (action.type) {
    case GET_STAFF_PROFILE_IMG:
      return action.payload;
    default:
      return state;
  }
};

export const getRolePermission = (state = false, action) => {
  switch (action.type) {
    case GET_ROLE_PERMISSIONS:
      return action.payload;
    default:
      return state;
  }
};

export const getAvailableStaff = (state = false, action) => {
  switch (action.type) {
    case ALL_STAFF_AVAILABLE:
      return action.payload;
    default:
      return state;
  }
};

export const getDocumentFilter = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const getAuthorizationFilters = (state = false, action) => {
  switch (action.type) {
    case SAVE_AUTHORIZATION_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const getClientDocumentFilter = (state = false, action) => {
  switch (action.type) {
    case GET_CLIENT_DOCUMENT_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const getBillDocFilter = (state = false, action) => {
  switch (action.type) {
    case GET_DOC_BILLING_FILTER:
      return action.payload;
    default:
      return state;
  }
};
export const getBillTransactionFilter = (state = false, action) => {
  switch (action.type) {
    case GET_BILL_TRANSACTION_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const getBillRemittanceFilter = (state = false, action) => {
  switch (action.type) {
    case GET_BILL_REMITTANCE:
      return action.payload;
    default:
      return state;
  }
};

export const getBillingClaimViewFilter = (state = false, action) => {
  switch (action.type) {
    case GET_BILL_CLAIM_VIEW:
      return action.payload;
    default:
      return state;
  }
};
export const staffFiter = (state = false, action) => {

  switch (action.type) {
    case SELECTED_STAFF_FILTER:
      return action.payload;

    default:

      return state;

  }

};

export const getClientAuthorizationFilters = (state = false, action) => {
  switch (action.type) {
    case SAVE_CLIENT_AUTHORIZATION_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export const getSchedulerFilter = (state = false, action) => {
  switch (action.type) {
    case GET_SCHEDULER_FILTER:
      return action.payload;
    default:
      return state;
  }
};
