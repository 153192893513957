import { Chip } from "@progress/kendo-react-buttons";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import useModelScroll from "src/cutomHooks/model-dialouge-scroll";
import { renderErrors } from "src/helper/error-message-helper";
import { getStaffComment } from "src/redux/actions";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import dummyImg from "../../../assets/images/dummy-img.png";
import { resizeFiles } from "../../../control-components/image-resizer/image-resizer";
import CustomSkeleton from "../../../control-components/skeleton/skeleton";
import useBirthDateCalculor from "../../../cutomHooks/birth-date-calculate/birth-date-calculate";
import ApiHelper from "../../../helper/api-helper";
import AppRoutes from "../../../helper/app-routes";
import { MaskFormatted } from "../../../helper/mask-helper";
import NOTIFICATION_MESSAGE from "../../../helper/notification-messages";
import {
  permissionEnum,
  userCanAccessStaffInfo,
  userIsSuperAdmin,
} from "../../../helper/permission-helper";
import AddStaffComment from "./add-staff-comment";
import cameraIcon from "../../../assets/images/camera.png";

import editPencil from "../../../assets/images/pencil-edit-02.png";

interface profileHeaderModel {
  profilePic?: any;
  getStaffDetail?: any;
  isDefaultImage?: boolean;
}

const StaffProfileHeader: React.FC<profileHeaderModel> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedStaffId = useSelector((state: any) => state?.selectedStaffId);
  const staffCommentResp = useSelector(
    (state: any) => state?.StaffReducer?.staffCommentResp
  );
  const getProfilePic = useSelector((state: any) => state?.getStaffProfileImg);
  const staffId = useSelector((state: any) => state.loggedIn?.staffId);
  const staffLoginInfo = useSelector((state: any) => state?.getStaffReducer);
  const [loading, setLoading] = useState<boolean>(false);
  const [calculatedAge, handleAge] = useBirthDateCalculor();
  const pathName = location.pathname.toLowerCase();
  const staffInfo = useSelector((state: any) => state?.getStaffDetails);
  const [openStaffComments, setOpenStaffComments] = useState<boolean>(false);
  const [isProfileImageSelectionOpen, setProfileImageSelectionOpen] =
    useState<boolean>(false);
  const [staffComment, setstaffComment] = useState(null);
  const [modelScroll, setScroll] = useModelScroll();
  const dispatch = useDispatch();
  const userAccessPermission = useSelector(
    (state: any) => state?.userAccessPermission
  );

  useEffect(() => {
    dispatch(getStaffComment(selectedStaffId));
  }, [selectedStaffId]);

  useEffect(() => {
    if (selectedStaffId !== null && staffInfo && staffInfo.dob && handleAge) {
      handleAge(staffInfo.dob);
    }
  }, [staffInfo]);

  useEffect(() => {
    if (staffCommentResp !== null) {
      setstaffComment(staffCommentResp);
    } else {
      setstaffComment(null);
    }
  }, [staffCommentResp]);

  const handleEditProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate(AppRoutes.EDIT_STAFF);
  };

  function getProfilePicFromProps(isSuperAdmin = false) {
    let profilePic = props?.profilePic;
    if (isSuperAdmin) {
      profilePic = profilePic || dummyImg;
    } else {
      profilePic = profilePic || getProfilePic.staffProfileImageUrl;
    }
    // console.log("final profilePic", profilePic)
    return profilePic;
  }

  const uploadStaffProfile = (profile) => {
    let data = {
      staffId: selectedStaffId,
      docName: profile.path,
      fileName: profile.path,
      type: "Profile Image",
    };
    setLoading(true);
    ApiHelper.multipartPostRequest(API_ENDPOINTS.GET_STAFF_S3_SIGNED_URL, data)
      .then((result) => {
        uploadFileToS3(result, profile);
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  const deleteStaffProfile = () => {
    setLoading(true);
    ApiHelper.deleteRequest(
      `${API_ENDPOINTS.DELETE_STAFF_PROFILE_IMAGE}${selectedStaffId}`
    )
      .then((result) => {
        NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_PHOTO);
        props?.getStaffDetail();
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error);
      });
  };

  const uploadFileToS3 = async (presignedPostData, file) => {
    const result = await fetch(presignedPostData.resultData.presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      body: file,
    });

    setLoading(false);
    if (result.ok) {
      props?.getStaffDetail();
      NotificationManager.success(NOTIFICATION_MESSAGE.UPLOAD_PHOTO);
    } else {
      renderErrors("Error uploading file to S3");
    }
  };

  const handleValueChange = (value) => {
    uploadStaffProfile(value);
  };
  let mobileNum = MaskFormatted(
    staffInfo ? staffInfo.mobile : "",
    "(999) 999-9999"
  );
  let phoneNum = MaskFormatted(
    staffInfo ? staffInfo.phone : "",
    "(999) 999-9999"
  );

  const handleAddComment = () => {
    setOpenStaffComments(true);
    setScroll(false);
  };
  const handleCloseComment = ({ updated }) => {
    setScroll(false);
    setOpenStaffComments(false);
  };

  const handleEditComment = () => {
    setOpenStaffComments(true);
    setScroll(false);
  };

  function openFilePicker(callback: any) {
    // Create a file input dynamically
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*, .jpg"; // Restrict to image files and .jpg specifically
    fileInput.multiple = false; // Ensure only a single file can be selected

    // Add an event listener to handle the selected file
    fileInput.onchange = (event: any) => {
      const files = Array.from(event.target.files);
      if (files.length > 0) {
        callback(files); // Pass files array to the callback
      }
    };

    // Trigger the file picker dialog
    fileInput.click();
  }

  const renderImageOptions = () => {
    setProfileImageSelectionOpen(true);
  };

  const renderProfileImageOptionsDialog = () => {
    if (!isProfileImageSelectionOpen) return null;
    return (
      <div
        className="profile-modal-overlay"
        onClick={() => setProfileImageSelectionOpen(false)} // Close dialog when clicking outside
      >
        <div
          className="profile-modal-dialog"
          onClick={(e) => e.stopPropagation()} // Prevent dialog close when clicking inside
        >
          <h3 className="profile-modal-title">Select an Action</h3>
          {/* Select Image Button */}
          <button
            onClick={() => {
              openFilePicker((files: any) => {
                resizeFiles(files, 200, 300).then((reSizedFiles) => {
                  handleValueChange(reSizedFiles[0]);
                });
              });
              setProfileImageSelectionOpen(false); // Close dialog after action
            }}
            className="submitButon"
          >
            Select Image
          </button>
          {/* Delete Image Button */}
          {getProfilePic?.isDefaultImage || props.isDefaultImage ? (
            <></>
          ) : (
            <button
              onClick={() => {
                deleteStaffProfile();
                setProfileImageSelectionOpen(false); // Close modal after action
              }}
              className="cancelBtn"
            >
              Delete Image
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="client-profileheader profile-edit-show d-flex justify-content-between align-items-start">
        <div className="edit-profile-left d-flex align-items-start tabletprofile-view">
          <div className="profile-image position-relative">
            {staffInfo?.id === staffId ||
            userIsSuperAdmin(staffLoginInfo?.roleId) ? (
              <div className="profile-image position-relative">
                <img
                  width={160}
                  height={140}
                  src={getProfilePicFromProps(true)}
                  alt="profileImage"
                  style={{ objectFit: "cover", borderRadius: "8px" }}
                />

                <div className="camera-photo">
                  <img
                    src={cameraIcon}
                    alt="cameraImg "
                    className="  cameraIcon"
                    onClick ={renderImageOptions}
                  />
                </div>
              </div>
            ) : (
              <div>
                {staffInfo?.id === staffId && (
                  // <span className="k-icon k-i-photo-camera camera-photo"></span>
                  <img
                    src={cameraIcon}
                    alt="cameraImg"
                    className="camera-photo cameraIcon"
                  />
                )}
                {getProfilePicFromProps() ? (
                  <img
                    width={160}
                    height={140}
                    src={getProfilePicFromProps()}
                    alt="profileImage"
                  />
                ) : (
                  <div>
                    <img src={dummyImg} alt="dummyImage" />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="content-inner">
            {!staffInfo ? (
              <CustomSkeleton shape="text" />
            ) : (
              <>
                <div className="d-flex flex-wrap flex-md-nowrap align-items-center">
                  <h4 className="address-title text-theme mb-2 mr-3 d-flex align-items-center">
                    {staffInfo?.firstName} {staffInfo.middleName}{" "}
                    {staffInfo?.lastName}
                  </h4>
                  <p className="mb-2 f-16 fw-500 pr-2">
                    (
                    {!staffInfo ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      staffInfo?.position
                    )}
                    )
                  </p>

                  <p className="mb-2 f-16 fw-500">
                    {!staffInfo ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      staffInfo?.roleName
                    )}
                  </p>
                </div>
              </>
            )}

            <ul className="list-unstyled mb-0 details-info">
              {/* <li className="d-flex mb-2 flex-wrap">
                <p className="mb-0 col-md-6 px-0 fw-500">Date of Birth</p>
                <p className="mb-0  col-md-6 px-0">
                  {staffInfo ? (
                    moment(staffInfo?.dob).format("M/D/YYYY")
                  ) : (
                    <CustomSkeleton shape="text" />
                  )}{" "}
                  {staffInfo || calculatedAge ? (
                    `(${calculatedAge}  years)`
                  ) : (
                    <CustomSkeleton shape="text" />
                  )}
                </p>
              </li> */}
              {staffInfo?.address ? (
                <li className="d-flex mb-2 flex-wrap align-items-center">
                  <p className="mb-0  col-md-6 px-0">
                    {!staffInfo ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      (userCanAccessStaffInfo(
                        staffLoginInfo,
                        userAccessPermission
                      ) ||
                        staffLoginInfo.isHumanResourcesManager) &&
                      staffInfo?.address
                    )}
                  </p>
                </li>
              ) : (
                ""
              )}

              {staffInfo?.city ||
              staffInfo?.stateCode ||
              staffInfo?.stateCode ||
              staffInfo?.zip ? (
                <li className="d-flex mb-2 flex-wrap align-items-center">
                  <p className="mb-0  col-md-6 px-0">
                    {!staffInfo ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      (userCanAccessStaffInfo(
                        staffLoginInfo,
                        userAccessPermission
                      ) ||
                        staffLoginInfo.isHumanResourcesManager) &&
                      (staffInfo?.city ? `${staffInfo?.city},` : "") +
                        " " +
                        (staffInfo?.stateCode
                          ? `${staffInfo?.stateCode}`
                          : "") +
                        " " +
                        (staffInfo?.zip ? staffInfo?.zip : "")
                    )}
                  </p>
                </li>
              ) : (
                " "
              )}
              <li className="d-flex mb-2 flex-wrap align-items-center">
                {(userCanAccessStaffInfo(
                  staffLoginInfo,
                  userAccessPermission
                ) ||
                  staffLoginInfo.isHumanResourcesManager) && (
                  <i className="fa-solid fa-mobile-alt mr-2 fa-sm"></i>
                )}
                <p className="mb-0  px-0">
                  {mobileNum ? (
                    (userCanAccessStaffInfo(
                      staffLoginInfo,
                      userAccessPermission
                    ) ||
                      staffLoginInfo.isHumanResourcesManager) &&
                    mobileNum
                  ) : (
                    <CustomSkeleton shape="text" />
                  )}
                </p>
              </li>
              <li className="d-flex mb-2 flex-wrap align-items-center">
                {(userCanAccessStaffInfo(
                  staffLoginInfo,
                  userAccessPermission
                ) ||
                  staffLoginInfo.isHumanResourcesManager) && (
                  <i className="fa-solid fa-phone mr-2 fa-sm"></i>
                )}
                <p className="mb-0  px-0">
                  {phoneNum ? (
                    (userCanAccessStaffInfo(
                      staffLoginInfo,
                      userAccessPermission
                    ) ||
                      staffLoginInfo.isHumanResourcesManager) &&
                    phoneNum
                  ) : (
                    <CustomSkeleton shape="text" />
                  )}
                </p>
              </li>
              <li className="d-flex mb-2 flex-wrap align-items-center">
                <i className="fa-solid fa-envelope mr-2 fa-sm"></i>
                <p className="mb-0   px-0">
                  {staffInfo?.email ? (
                    staffInfo?.email
                  ) : (
                    <CustomSkeleton shape="text" />
                  )}
                </p>
              </li>
              <li className="d-flex mb-2 flex-wrap align-items-center">
                {(userIsSuperAdmin(staffLoginInfo?.roleId) ||
                  staffLoginInfo.isHumanResourcesManager ||
                  userAccessPermission[permissionEnum.EDIT_STAFF_PROFILE]) &&
                  !staffComment && (
                    <Chip
                      text="Add Comment"
                      value="chip"
                      icon={"fa-solid fa-plus"}
                      rounded={"large"}
                      fillMode={"solid"}
                      size={"medium"}
                      onClick={handleAddComment}
                    />
                  )}

                {staffComment && (
                  <div style={{ marginBottom: 10 }} className="editComment">
                    {staffComment}

                    {(userIsSuperAdmin(staffLoginInfo?.roleId) ||
                      staffLoginInfo.isHumanResourcesManager ||
                      userAccessPermission[
                        permissionEnum.EDIT_STAFF_PROFILE
                      ]) && (
                      <span
                        className="fa-solid fa-pencil ml-2 "
                        onClick={handleEditComment}
                        style={{}}
                      ></span>
                    )}
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        {pathName === "/staff/profile" &&
          (userAccessPermission[permissionEnum.EDIT_STAFF_PROFILE] ||
            (userAccessPermission[permissionEnum.EDIT_DEMOGRAPHICS] &&
              selectedStaffId === staffLoginInfo.id)) && (
            <button
              className="submitButon fixed-size-button"
              onClick={handleEditProfile}
            >
              <span className="fa-solid fa-pencil mr-2"></span>Edit Profile
            </button>
          )}

        {openStaffComments && (
          <AddStaffComment
            comments={staffComment}
            onClose={handleCloseComment}
          />
        )}

        {isProfileImageSelectionOpen && renderProfileImageOptionsDialog()}
      </div>
    </>
  );
};
export default StaffProfileHeader;
