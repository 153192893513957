import {
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import ApiHelper from "../../../../helper/api-helper";
import { showError } from "../../../../util/utility";
import DeleteDialogModal from "../../../../control-components/custom-delete-dialog-box/delete-dialog";
import StaffSettingTemplate from "./staffSettingTemplate";
import searchIcon from "../../../../assets/images/search.png";
import settingIcon2 from "../../../../assets/images/setting-icon2.svg";
import { Input } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-data-query";
import deleteTrash from "../../../../assets/images/trash2.png";
import { StaffService } from "src/services/staffService";
import { useSelector } from "react-redux";
import { userIsAdmin, userIsSuperAdmin } from "src/helper/permission-helper";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import { useNavigate } from "react-router";
import AccessDenied from "src/app-modules/access/access-denied";
import { API_ENDPOINTS } from "src/services/api-endpoints";


const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function DocumentTemplateList() {
  // States
  const [documentTemplateList1, setDocumentTemplateList1] = useState([])
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<any>(10);
  const [setting, setSetting] = useState(false);
  const [settingTemplateId, setSettingTemplateId] = useState();
  const [confirm, setConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchApiQuery, setsearchApiQuery] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedState, setSelectedState] = React.useState({});
  const [sort, setSort] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [propTemplateAssignedId, setPropTemplatedAssignedId] = useState()
  const navigate = useNavigate();

  const staffLoginInfo = useSelector((state: any) => state.getStaffReducer);//useSelector((state: ComponentState) => state.getStaffReducer);


  async function getAllDocumentTemplates() {
    ApiHelper.getRequest(API_ENDPOINTS.GET_ALL_STAFF_DOCUMENT_TEMPLATES_WITH_SETTINGS)
      .then((result) => {
        if (result.resultData) {
          setDocumentTemplateList1(
            result.resultData
          );
        }
        setsearchApiQuery(result.resultData);
      })
      .catch((err) => {
        showError(err, "Fetch Document Templates");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function deleteDocumentTemplate(id) {
    StaffService.deleteStaffDocTemplate(id).then((response) => {
      NotificationManager.success("Deleted Document Template Successfully")
      getAllDocumentTemplates()
    })
      .catch((err) => {
        showError(err, "Delete Document Template")
      })
      .finally(() => {
        hideConfirmPopup();
        setLoading(false);
      })
  }

  /* ============================= useEffect functions ============================= */

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getAllDocumentTemplates();
    if (localStorage.getItem("document-template-pagesize")) {
      setPageSize(localStorage.getItem("document-template-pagesize"));
    }
  }, []);

  /* ============================= Event functions ============================= */


  const hideConfirmPopup = () => {
    setConfirm(false);
  };

  const handleDelete = () => {
    handleDeleteDocumentTemplate(deleteId);
  };

  function pageChange(event) {
    let skip = event?.page?.skip;
    let take = event?.page?.take;

    storePage(take);
    setPage(skip);
    setPageSize(take);
    window.scrollTo(0, 0);
  }



  function handleDeleteDocumentTemplate(id) {
    setLoading(true);
    deleteDocumentTemplate(id);
  }


  function handleSettingTemplateView(id, isHtml) {
    setSetting(!setting);
    setSettingTemplateId(id);
    if (setting === false) {
    } else {
    }
  }


  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
    ],
  };

  const storePage = (pageValue) =>
    localStorage.setItem("document-template-pagesize", pageValue);

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setPage(currentPage);
      setDocumentTemplateList1(searchApiQuery);
    } else {
      const filterResult = searchApiQuery.filter((item: any) =>
        item.templateName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      if (filterResult.length > 0) {
        setCurrentPage(page);
        setPage(0);
      }
      setDocumentTemplateList1(filterResult);
    }
    setSearchQuery(e.target.value);
  };
  function onBack() {
    navigate(-1);
  }



  return (
    ((userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId)) ?
      <>
        <div className="d-flex align-items-center">
          <button
            type="button"
            value="BACK"
            onClick={onBack}
            className="pb-2 border-0 bg-transparent arrow-rotate p-0 m-0 mb-0"
          >
            <i className="fa-solid fa-arrow-left mr-2"></i>
          </button>
          <h4 className="address-title text-grey">
            <span className="f-24">Staff Document Templates</span>
          </h4>
        </div>
        <div className="grid-table ">
          <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
            <div className="content-search-filter ">
              <img src={searchIcon} alt="" className="search-icon" />
              <Input
                className="icon-searchinput"
                placeholder="Search Template Name"
                value={searchQuery}
                onChange={(e) => handleFilter(e)}
              />
            </div>
          </div>

          <div className="grid-table table-heading-auth blueTheme">
            {/* {loading && <GridLoader />} */}
            {loading ?
              <TableRowsLoader rowsNum={10} /> :
              <Grid
                style={{
                  height: documentTemplateList1?.length > 0 ? "100%" : "250px",
                }}
                data={orderBy(
                  documentTemplateList1?.slice(page, +pageSize + page),
                  sort
                ).map((item: any) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                skip={page}
                take={pageSize}
                onPageChange={pageChange}
                total={documentTemplateList1?.length}
                className="pagination-row-cus"
                pageable={{
                  pageSizes: [10, 20, 30],
                }}
                sort={sort}
                sortable={true}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                filterOperators={filterOperators}
              >
                <GridColumn
                  title="Name"
                  sortable={false}
                  field="templateName"
                  className="cursor-default"
                  width="250px"
                />

                <GridColumn
                  title="Can Apply Sig?"
                  sortable={false}
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.canApplySignature;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>
                    );
                  }}
                  width="150px"
                />
                <GridColumn
                  sortable={false}
                  width="185px"
                  title="Show File Attatchment"
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.showFileAttachment;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default  f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>

                    );

                  }}

                />

                <GridColumn
                  sortable={false}
                  width="180px"
                  title="Show Session Time"
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.showSessionTime;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default  f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  sortable={false}
                  width="180px"
                  title="Show Review Services"
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.showReviewServices;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default  f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  width="180px"
                  sortable={false}
                  title="Show Contact Type"
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.showContactType;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default  f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  width="180px"
                  sortable={false}
                  title="Show Clinical Skills"
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.showClinicalSkills;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default  f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  width="180px"
                  sortable={false}
                  title="Show Goals"
                  cell={(props) => {
                    let field = props.dataItem?.templateSettings?.showGoals;
                    return (
                      <td className="cursor-default">
                        {field === true ? (
                          <span
                            className="fa fa-check-circle cursor-default  f-18 "
                            style={{ color: "green" }}
                          ></span>
                        ) : (
                          <span className="fa fa-times-circle cursor-default f-18 cross-icon-color"></span>
                        )}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  title=""
                  width="120px"
                  className="cursor-inherit cursor-default"
                  filterable={false}
                  cell={(props) => {
                    let field = props.dataItem.id;
                    let propTemplateAssignedId = props.dataItem.templateAssignedId
                    return (
                      ((userIsSuperAdmin(staffLoginInfo?.roleId) || userIsAdmin(staffLoginInfo?.roleId)) ?
                        <td className="cursor-default" >
                          <div className="row-3">
                            <div
                              className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base m-2 cursor-pointer settings-btn" data-toggle="tooltip" data-placement="top" title="Settings"
                              onClick={() => { setSettingTemplateId(field); setPropTemplatedAssignedId(propTemplateAssignedId); setSetting(true) }}
                            >
                              <div className="k-chip-content cursor-pointer" >
                                <Tooltip
                                  anchorElement="target"
                                  position="top"
                                >
                                  <img src={settingIcon2} alt="setting-icon2" title="Settings" aria-hidden="true" style={{ maxWidth: "18px" }} className="cursor-pointer" />
                                </Tooltip>
                              </div>
                            </div>
                            <div
                              className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base delete-btn cursor-pointer" data-toggle="tooltip" data-placement="top" title="Delete"
                              onClick={() => { setDeleteId(field); setConfirm(true) }}
                            >
                              <div className="k-chip-content">
                                <Tooltip
                                  anchorElement="target"
                                  position="top"
                                >
                                  <img src={deleteTrash} alt=" delete trash " title="Delete" aria-hidden="true" className="cursor-pointer" />

                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </td> : ""
                      )
                    );
                  }}
                />
              </Grid>}
          </div>
          {setting && (
            <StaffSettingTemplate
              onClose={handleSettingTemplateView}
              isSelectedId={settingTemplateId}
              getAllDocumentTemplates={getAllDocumentTemplates}
              templateAssignedIdProp={propTemplateAssignedId}
            />
          )}
          {confirm && (
            <DeleteDialogModal
              onClose={hideConfirmPopup}
              title="Document Template"
              message="document template"
              handleDelete={handleDelete}
            />
          )}
        </div>
      </> : <AccessDenied></AccessDenied>
    ));
}
export default DocumentTemplateList;