import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ClientService } from "../../../../../../services/clientService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import APP_ROUTES from "src/helper/app-routes";
import { useLocation } from "react-router";
import { renderErrors } from "src/helper/error-message-helper";

const HealthQuestionnaire = ({ list, questionId, response,isPrint }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const [loading, setLoading] = useState(false);
  // State for storing list items and their respective dropdown values
  const [listItems, setListItems] = useState(list);
  const OPTIONS_MAP = {
    "0": "0 - Not at all",
    "1": "1 - Several days",
    "2": "2 - More than half day",
    "3": "3 - Nearly every day"
  };
  useEffect(() => {
    calculateSum();
  }, [listItems]);

  useEffect(() => {
    setListItems(list);
  }, [list]);

  useEffect(() => {
    const updatedGAD = list.map((question) => {
      const matchingQuestion = response?.find(
        (item) => item.questionId === question.questionId
      );
      if (matchingQuestion) {
        return { ...question, value: parseInt(matchingQuestion.value) };
      }
      return question;
    });
    setListItems(updatedGAD);
  }, [response]);

  // State for storing the total sum
  const [totalSum, setTotalSum] = useState(0);

  // Function to handle change in dropdown values
  const handleDropdownChange = (itemId, value) => {
    // Update the listItems state with the new value
    const updatedListItems = listItems?.map((item) => {
      if (item.id === itemId) {
        return { ...item, value: parseInt(value) };
      }
      return item;
    });
    setListItems(updatedListItems);
  };
  const renderAnswer = (item) => {
    const value = item.value;
    return (
          <select className="form-select" disabled>
            <option>{OPTIONS_MAP[value]}</option>
          </select>
        );
  }

  // Function to calculate the sum of all dropdown values
  const calculateSum = () => {
    const sum = listItems?.reduce(
      (acc, currentItem) => acc + currentItem.value,
      0
    );
    setTotalSum(sum);
  };

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId,
      totalSum,
      listItems,
      questionId
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error.message);
      });
  };

  return (
    <div className="col-12 selectMinWidth">
      <div className="mt-3  phq_main_table">
      <div className="head-3">PHQ9 - Patient Health Questionnaire</div>
        <label className="border-bottom pb-2">
          Over the last two weeks, How often have you been bothered by any of
          the following? (Use the drop down list to select the application
          amount.)
        </label>
      </div>
      {listItems?.map((item) => (
        <div 
            key={item.id}
            className={`row mb-3 py-2 border-bottom ml-1 ${
              [4].includes(item.id) ? "q-mb-70 " : ""
            }`}
          >
          <div className="col-12 ">
            <div>
              <label>
                {item.id}. {item.name}:
              </label>
            </div>
          </div>
          <div className="col-12 select_table_phq select-table-drops">
          {isPrint
            ? renderAnswer(item)
            : (
            <select
              value={item.value}
              onChange={(e) => handleDropdownChange(item.id, e.target.value)}
              disabled={state == null ? false : true}
            >
              <option value={0}>0 - Not at all</option>
              <option value={1}>1 - Several days</option>
              <option value={2}>2 - More than half day</option>
              <option value={3}>3 - Nearly every day</option>
            </select>
            )}
          </div>
        </div>
      ))}
      <div className="text-start justify-content-start total_phq col-12">
        <p className="mb-0">Total Score: </p>
        <p className="submitButon mb-0">{totalSum}</p>
      </div>

      {state == null && !isPrint ? (
        <div className="text-start mt-3 col-12">
          <button onClick={handleSubmit} className="submitButon ">
            Submit
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="keep-together">
      <h6>Notes</h6>
      <label className="">
        Since the questionnaire relies on patient self-report, all responses
        should be verified by the clinician, and a definitive diagnosis is made
        on clinical grounds taking into account how well the patient understood
        the questionnaire, as well as other relevant information from the
        patient.
      </label>
      <label>
        Diagnoses of Major Depressive Disorder or Other Depressive Disorder also
        require impairment of social, occupational, or other important areas of
        functioning (Question #10) and ruling out normal bereavement, a history
        of a Manic Episode (Bipolar Disorder), and a physical disorder,
        medication, or other drug as the biological cause of the depressive
        symptoms.
      </label>
      <label>
        To monitor severity over time for newly diagnosed patients or patients
        in current treatment for depression:
      </label>
      </div>
      <ul className="keep-together">
        <li className="f-14">
          Patients may complete questionnaires at baseline and at regular
          intervals (eg, every 2 weeks) at home and bring them in at their next
          appointment for scoring or they may complete the questionnaire during
          each scheduled appointment.
        </li>
        <li className="f-14">
          Results may be included in patient files to assist you in setting up a
          treatment goal, determining degree of response, as well as guiding
          treatment intervention.
        </li>
      </ul>
      <p className="f-12 mt-3">
        Copyright © 1999 Pfizer Inc. All rights reserved. Reproduced with
        permission.
      </p>
    </div>
  );
};

export default HealthQuestionnaire;
