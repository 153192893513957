import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { clientAuditLogListChangedAction, clientLedgerBalanceChanged, getClientComplianceList, getClientMedicationByClientId, getCpAccAccess, loaderAction, loadingInClient, saveAttachedDocForCompliance, saveBillingClaims, saveClientAuditLogList, saveClientAuditLogMetaData, saveClientComplianceList, saveClientLedgerByClientId, saveClientLedgerById, saveClientLedgerPaymentTypes, saveClientListChangedBoolean, saveClientMedicationByClientId, saveClientMedicationById, saveClientStatus, saveClinicFlag, saveCpAccountAccess, saveCreateResponse, saveCurrentLedgerBalance, saveDocLoadingState, saveDuplicateClients, saveMultipleClientList, saveReviewGoalByGoalId, saveTpStaffTypeDll, saveUnreadCount, storeAssignFlagToClient, saveSocialDeterminantsResponse, saveContactTypeList, saveCollateralContact, saveCollateralContactById, saveClientDocAttachments, loaderClients, saveComplianceAuditHistory, savePosList, SaveReferralSourceDelete, SaveReferralProviderDelete, saveServiceMinReportByClientId, clientStatusLoadingState, refreshTP, saveClientFileForTreatmentPlan, saveDocumentToCp, updateSaveRecordDocumentToCp, saveUploadFileFinalResponse, saveTpPrintedFiles, getTpPrintedFiles, saveMaritalStatus, saveGenderIdentity, saveSexualOrientation,saveClientMedicalHistory ,getClientMedicalHistory,getFamilyMedicalHistory,saveFamilyMedicalHistory } from '../actions';
import { clientServices } from 'src/services/clientServices';
import { ClientBillingClaimResponse, ClientComplianceListResponse, CompleteComplianceResponse, ResponseMultiClientList, clientStatusResponse, clinicFlagResponse, genderIdendityResponse, maritalStatusResponse, sexualOrientationResponse, unreadCountResponse,ClientMedicalHistoryResponseModal,FamilyMedicalHistoryResponseModal } from 'src/dataModels/clientResponse';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { GetTpStaffTypesResponse, ReviewGoalResponse } from 'src/dataModels/clinicResponseModels';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';
import { UploadTreatmentPlanToCpModel } from 'src/dataModels/clientDataModel';
import { GenericResponse } from 'src/dataModels/authSetModels';
import { customAxiosMultipart } from 'src/services/useExPressApiMultipart';
import axios from 'axios';
import { saveAs } from '@progress/kendo-file-saver';
import { renderErrors } from 'src/helper/error-message-helper';




export function* clientBillingClaimSaga() {
  yield takeLatest(TYPES.GET_CLIENT_BILLING_CLAIMS, claimWorker);
}

function* claimWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ClientBillingClaimResponse = yield clientServices.billingClaims(param['payload']);
    yield put(saveBillingClaims(response.resultData));
    yield put(loaderAction(false));
  } catch (err) {
  }
}

export function* getClientComplianceListSaga() {
  yield takeLatest(TYPES.GET_CLIENT_COMPLIANCE_LIST, getClientComplianceListWorker);
}

function* getClientComplianceListWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ClientComplianceListResponse = yield clientServices.clientCompliance(param['payload']);
    yield put(loaderAction(false));
    yield put(saveClientComplianceList(response))
  } catch (err) {
  }
}

export function* completeComplianceSaga() {
  yield takeLatest(TYPES.COMPLETE_COMPLIANCE, completeComplianceWorker);
}

function* completeComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: CompleteComplianceResponse = yield clientServices.completeCompliance(param['payload']);
    NotificationManager.success(NOTIFICATION_MESSAGE.COMPLETE_COMPLIANCE);
    yield put(getClientComplianceList(param['payload'].clientId))
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* deleteManuallyCompletedComplianceSaga() {
  yield takeLatest(TYPES.DELETE_MANUALLY_COMPLETED_COMPLIANCE, deleteManuallyCompletedComplianceWorker);
}

function* deleteManuallyCompletedComplianceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.deleteManuallyCompletedCompliance(param['payload']['complianceId']);
    yield put(loaderAction(false));
    yield put(getClientComplianceList(param['payload'].clientId))
    NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_COMPLETE_COMPLIANCE);

  }
  catch (err) {
  }
}

export function* getClinicFlagSaga() {
  yield takeLatest(TYPES.GET_CLINIC_FLAG, getClinicFlagWorker);
}

function* getClinicFlagWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: clinicFlagResponse = yield clientServices.getClinicFlag();
    yield put(loaderAction(false));
    yield put(saveClinicFlag(response))


  }
  catch (err) {
  }
}

export function* saveAssignFlagToClientSaga() {
  yield takeLatest(TYPES.ASSIGN_FLAG_TO_CLIENT, saveAssignFlagToClientWorker);
}

function* saveAssignFlagToClientWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveClinicFlag(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.ASSIGN_FLAG_TO_CLINET);
    yield put(storeAssignFlagToClient(response))

  }
  catch (err) {
  }
}

export function* getClientStatusSaga() {
  yield takeLatest(TYPES.GET_CLIENT_STATUS, getClientStatusWorker);
}

function* getClientStatusWorker() {
  try {
    yield put(loaderAction(true));
    const response: clientStatusResponse = yield clientServices.getClientStatus();
    yield put(loaderAction(false));
    yield put(saveClientStatus(response))
  }
  catch (err) {
  }
}

export function* saveSelectedClientStatusSaga() {
  yield takeLatest(TYPES.SAVE_SELECTED_CLIENT_STATUS, saveSelectedClientStatusWorker);
}

function* saveSelectedClientStatusWorker(param: any) {
  try {
    yield put(clientStatusLoadingState(false));
    const response: any = yield clientServices.saveSelectedClientStatus(param['payload']);
    yield put(clientStatusLoadingState(true));
    NotificationManager.success(NOTIFICATION_MESSAGE.CHANGE_CLIENT_STATUS_OF_SELECTED_CLIENTS);
  }
  catch (err) {
  }
}
export function* saveSelectedClientEligibilitySaga() {
  yield takeLatest(TYPES.SAVE_SELECTED_CLIENT_ELIGIBILITY, saveSelectedClientEligibilityWorker);
}

function* saveSelectedClientEligibilityWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: any = yield clientServices.saveSelectedClientEligibility(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_ELIGIBILITY);
  }
  catch (err) {
  }
}

export function* getUnreadCountSaga() {
  yield takeLatest(TYPES.GET_UNREAD_COUNT, getUnreadCountWorker);
}

function* getUnreadCountWorker() {
  try {
    yield put(loaderAction(true));
    const response: unreadCountResponse = yield clientServices.getUnreadCount();
    yield put(loaderAction(false));
    yield put(saveUnreadCount(response))
  }
  catch (err) {
  }
}

export function* getMultiClientListSaga() {
  yield takeLatest(TYPES.GET_MULTIPLE_CLIENTS, getmultiClientList);
}

function* getmultiClientList(param: any) {
  try {
    // yield put(loaderAction(true));
    const response: ResponseMultiClientList = yield clientServices.getMultiClientList(param['payload']);
    // yield put(loaderAction(false));
    yield put(saveMultipleClientList(response.resultData))
  }
  catch (err) {
  }
}

export function* getTpStaffTypesDllSaga() {
  yield takeLatest(TYPES.GET_TP_STAFF_TYPE_DLL, getTpStaffTypesDllList);
}

function* getTpStaffTypesDllList(param: any) {
  try {
    yield put(loaderAction(true));
    const response: GetTpStaffTypesResponse = yield clientServices.getTpStaffType();
    yield put(loaderAction(false));
    yield put(saveTpStaffTypeDll(response.resultData))
  }
  catch (err) {
  }
}

export function* insertReviewGoalSaga() {
  yield takeLatest(TYPES.INSERT_REVIEW_GOAL, insertReviewGoalWorker);
}

function* insertReviewGoalWorker(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.insertReviewGoal(param['payload']);
    yield put(loaderAction(false));
    NotificationManager.success(NOTIFICATION_MESSAGE.INSERT_REVIEW_GOAL);
  }
  catch (err) {
  }
}

export function* getReviewGoalByGoalIdSaga() {
  yield takeLatest(TYPES.GET_REVIEW_GOAL_BY_GOAL_ID, getReviewGoalByGoalId);
}

function* getReviewGoalByGoalId(param: any) {
  try {
    yield put(loaderAction(true));
    const response: ReviewGoalResponse = yield clientServices.getReviewGoalByGoalId(param['payload']);
    yield put(loaderAction(false));
    yield put(saveReviewGoalByGoalId(response.resultData))
  }
  catch (err) {
  }
}

export function* deleteReviewGoalSaga() {
  yield takeLatest(TYPES.DELETE_REVIEW_GOAL, deleteReviewGoal);
}

function* deleteReviewGoal(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.deleteReviewGoal(param['payload']);
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* checkDuplicateClientSaga() {
  yield takeLatest(TYPES.CHECK_DUPLICATE_CLIENTS, checkDuplicateClientWorker);
}

function* checkDuplicateClientWorker(param: any) {
  try {
    // yield put(loaderAction(true));
    const response = yield clientServices.checkDuplicateClient(param['payload']);
    if (response && response.resultData)
      yield put(saveDuplicateClients(response.resultData));
  }
  catch (err) {
  }
}

export function* getClientLedgerByClientIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_BY_CLIENT_ID, getClientLedgerByClientIdWorker);
}

function* getClientLedgerByClientIdWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response = yield clientServices.getClientLedgerByClientId(param['payload']);
    if (response && response.resultData) {
      yield put(clientLedgerBalanceChanged(true))
    }
    yield put(saveClientLedgerByClientId(response.resultData));
    yield put(saveDocLoadingState(false));
  }
  catch (err) {
  }
}

export function* insertClientLedgerRecordSaga() {
  yield takeLatest(TYPES.INSERT_CLIENT_LEDGER_RECORD, insertClientLedgerRecordWorker);
}

function* insertClientLedgerRecordWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertClientLedgerRecord(param['payload']);
    if (response && response.resultData) {
      yield put(saveClientListChangedBoolean(true));
      yield put(loaderAction(false));
    }
  }
  catch (err) {
  }
}

export function* deleteClientLedgerRecordSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_LEDGER_RECORD, deleteClientLedgerRecordWorker);
}

function* deleteClientLedgerRecordWorker(param: any) {
  try {
    yield put(saveDocLoadingState(true));
    const response = yield clientServices.deleteClientLedgerRecordWithId(param['id']);
    if (response && response?.resultData) {
      yield put(saveClientListChangedBoolean(true));
    }
    yield put(saveDocLoadingState(false));
  }
  catch (err) {
  }
}


export function* getPaymentTypesClientLedgerSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_PAYMENT_TYPES, getPaymentTypesClientLedgerWorker);
}

function* getPaymentTypesClientLedgerWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerPaymentTypes(param['isActive']);
    yield put(saveClientLedgerPaymentTypes(response.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getClientLedgerByIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_LEDGER_BY_ID, getClientLedgerByWorker);
}

function* getClientLedgerByWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerById(param['id']);
    yield put(saveClientLedgerById(response.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getCurrentLedgerBalanceSaga() {
  yield takeLatest(TYPES.GET_CURRENT_LEDGER_BALANCE, getCurrentLedgerBalanceWorker);
}

function* getCurrentLedgerBalanceWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientLedgerCurrentBalance(param['payload']);
    yield put(saveCurrentLedgerBalance(response?.resultData?.currentBalance));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* updateClientLedgerSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_LEDGER, updateClientLedgerWorker);
}

function* updateClientLedgerWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateClientLedger(param['payload']);
    if (response && response.resultData) {
      yield put(saveClientListChangedBoolean(true))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getClientAuditSaga() {
  yield takeLatest(TYPES.GET_CLIENT_AUDIT_LOG_LIST, getClientAuditWorker);
}

function* getClientAuditWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientAuditLogList(param['payload']);
    if (response.statusCode === 200) {
      yield put(saveClientAuditLogList(response.resultData));
      yield put(saveClientAuditLogMetaData(response.metaData));
      yield put(clientAuditLogListChangedAction(false));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}


export function* getDocForComplianceSaga() {
  yield takeLatest(TYPES.GET_COMPLIANCE_ATTACHED_DOC_LIST, fetchComplianceDocWorker);
}

function* fetchComplianceDocWorker(param: any) {
  try {
    const response = yield clientServices.getDocumentsForCompliance(param['payload']);
    if (response && response?.resultData) {
      yield put(saveAttachedDocForCompliance(response?.resultData));
    }
  }
  catch (err) {
  }
}


export function* getClientPortalAccountAccessSaga() {
  yield takeLatest(TYPES.GET_CP_ACC_ACCESS_LIST, fetchCPaccessWorker);
}

function* fetchCPaccessWorker(param: any) {
  try {
    yield put(loadingInClient(true));
    const response = yield clientServices.getClientAccessAccount();
    if (response && response?.resultData) {
      yield put(saveCpAccountAccess(response?.resultData));
    }
    yield put(loadingInClient(false));
  }
  catch (err) {
    yield put(loadingInClient(false));
  }
}

export function* createAccForCPSaga() {
  yield takeLatest(TYPES.CREATE_CP_ACC_ACCESS, createAccWorker);
}

function* createAccWorker(param: any) {
  try {
    yield put(loadingInClient(true));
    const response = yield clientServices.createClientPortalAccessAccount(param['payload']);
    if (response && response?.status) {
      yield put(saveCreateResponse(200));
      NotificationManager.success("New Account for Client portal is added");
      yield put(getCpAccAccess());
    }
    yield put(loadingInClient(false));
  }
  catch (err) {
    yield put(loadingInClient(false));
  }
}

export function* updateAccForCPSaga() {
  yield takeLatest(TYPES.UPDATE_CP_ACC_ACCESS, updateAccWorker);
}

function* updateAccWorker(param: any) {
  try {
    yield put(loadingInClient(true));
    const response = yield clientServices.updateClientPortalAccess(param['payload']);
    if (response && response?.status) {
      NotificationManager.success("Account updated successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loadingInClient(false));
  }
  catch (err) {
    yield put(loadingInClient(false));
  }
}

export function* getAccCPDetailSaga() {
  yield takeLatest(TYPES.GET_CP_ACC_ACCESS_DETAIL, detailAccWorker);
}

function* detailAccWorker(param: any) {
  try {
    const response = yield clientServices.getDetailCpAccountAccessDetail(param['payload']);
    if (response && response?.resultData) {
      yield put(getCpAccAccess());
    }
  }
  catch (err) {
  }
}

export function* resetAccPwdSaga() {
  yield takeLatest(TYPES.RESET_CP_ACCESS_PWD, resetWorker);
}

function* resetWorker(param: any) {
  try {
    const response = yield clientServices.resetPasswordForCpAccess(param['payload']);
    if (response && response?.resultData) {
      NotificationManager.success("Password reset email has been sent");
    }
  }
  catch (err) {
  }
}

export function* getClientMedicationByClientIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_MEDICATION_BY_CLIENT_ID, getClientMedicationByClientIdWorker);
}

function* getClientMedicationByClientIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientMedicationByClientId(param['payload']);
    if (response && response.statusCode === 200) {
      yield put(saveClientMedicationByClientId(response?.resultData));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
} export function* getClientMedicationByIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_MEDICATION_BY_ID, getClientMedicationByIdWorker);
}

function* getClientMedicationByIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getClientMedicationById(param['payload']);
    yield put(saveClientMedicationById(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
} export function* deleteClientMedicationSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_MEDICATION, deleteClientMedicationWorker);
}

function* deleteClientMedicationWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const { deleteMedicateId } = param['payload'];
    const { selectedClientId } = param['payload'];
    const response = yield clientServices.deleteClientMedication(deleteMedicateId);
    if (response && response.statusCode === 200) {
      const data = {
        id: selectedClientId,
        isActive: true
      }
      yield put(getClientMedicationByClientId(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
} export function* updateClientMedicationSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_MEDICATION, updateClientMedicationWorker);
}

function* updateClientMedicationWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateClientMedication(param['payload']);
    if (response && response.statusCode === 200) {
      const data = {
        id: param['payload']?.clientId,
        isActive: true
      }
      yield put(getClientMedicationByClientId(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
} export function* insertClientMedicationSaga() {
  yield takeLatest(TYPES.INSERT_CLIENT_MEDICATION, insertClientMedicationWorker);
}

function* insertClientMedicationWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertClientMedication(param['payload']);
    if (response && response.statusCode === 200) {
      const data = {
        id: param['payload']?.clientId,
        isActive: true
      }
      yield put(getClientMedicationByClientId(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* insertSocialDeterminantsSaga() {
  yield takeLatest(TYPES.SAVE_SOCIAL_DETERMINANTS, insertSocialDeterminantsWorker);
}

function* insertSocialDeterminantsWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertSocialDeterminants(param['payload']);
    if (response && response?.status) {
      NotificationManager.success("Social Determinants Save successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* getSocialDeterminantsSaga() {
  yield takeLatest(TYPES.GET_SOCIAL_DETERMINANTS, getSocialDeterminantsWorker);
}

function* getSocialDeterminantsWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getSocialDeterminants(param['payload']);
    yield put(saveSocialDeterminantsResponse(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* updateSocialDeterminantsSaga() {
  yield takeLatest(TYPES.UPDATE_SOCIAL_DETERMINANTS, updateSocialDeterminantsWorker);
}

function* updateSocialDeterminantsWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateSocialDeterminant(param['payload']);
    if (response && response?.status) {
      NotificationManager.success("Social Determinants Update successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}



export function* getContactTypeListSaga() {
  yield takeLatest(TYPES.GET_CONTACT_TYPE_LIST, getContactTypeListWorker);
}

function* getContactTypeListWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getContactTypeList();
    yield put(saveContactTypeList(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getCollateralContactSaga() {
  yield takeLatest(TYPES.GET_CLIENT_COLLATERAL_CONTACT, getCollateralContactWorker);
}

function* getCollateralContactWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getCollateralContact(param['payload']);
    yield put(saveCollateralContact(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* insertCollateralContactInfoSaga() {
  yield takeLatest(TYPES.SAVE_CLIENT_COLLATERAL_CONTACT_INFO, insertCollateralContactInfoWorker);
}

function* insertCollateralContactInfoWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.insertCollateralContactInfo(param['payload']);
    if (response && response?.status) {
      NotificationManager.success("Collateral Contact added successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* updateCollateralContactInfoSaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_COLLATERAL_CONTACT_INFO, updateCollateralContactInfoWorker);
}

function* updateCollateralContactInfoWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.updateCollateralContactInfo(param['payload']);
    if (response && response?.status) {
      NotificationManager.success("Collateral Contact updated successfully");
      yield put(saveCreateResponse(200));
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* deleteCollateralContactSaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_COLLATERAL_CONTACT, deleteCollateralContactWorker);
}

function* deleteCollateralContactWorker(param: any) {
  try {
    yield put(loaderAction(true));
    yield clientServices.deleteCollateralContact(param['payload']);
    NotificationManager.success("Collateral Contact deleted successfully");
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getCollateralContactByIdSaga() {
  yield takeLatest(TYPES.GET_CLIENT_COLLATERAL_CONTACT_BY_ID, getCollateralContactByIdWorker);
}

function* getCollateralContactByIdWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getCollateralContactById(param['payload']);
    yield put(saveCollateralContactById(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}


export function* getClientDocAttachmentSaga() {
  yield takeLatest(TYPES.GET_CLIENT_DOCUMENT_ATTACHMENTS, attachmentWorker);
}

function* attachmentWorker(param: any) {
  try {
    yield put(loaderClients(true));
    const response = yield clientServices.docAttachments(param['payload']);
    yield put(saveClientDocAttachments(response));
    yield put(loaderClients(false));
  }
  catch (err) {
  }
}

export function* getComplianceAuditHistorySaga() {
  yield takeLatest(TYPES.GET_COMPLIANCE_AUDIT_HISTORY, getComplianceAuditHistoryWorker);
}
function* getComplianceAuditHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getComplianceAuditHistory(param['payload']);
    yield put(saveComplianceAuditHistory(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getPosListSaga() {
  yield takeLatest(TYPES.GET_POS_LIST, getPosListWorker);
}
function* getPosListWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getPosList();
    yield put(savePosList(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* referralSourceDeleteSaga() {
  yield takeLatest(TYPES.REFERRAL_SOURCE_DELETE, referralSourceDeleteWorker);
}
function* referralSourceDeleteWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.referralSourceDelete(param['payload']);
    yield put(SaveReferralSourceDelete(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* referralProviderDeleteSaga() {
  yield takeLatest(TYPES.REFERRAL_PROVIDER_DELETE, referralProviderDeleteWorker);
}
function* referralProviderDeleteWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.referralProviderDelete(param['payload']);
    yield put(SaveReferralProviderDelete(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getMinServiceReportSaga() {
  yield takeLatest(TYPES.GET_SERVICE_MIN_REPORT_BY_CLIENT_ID, getMinServiceReportWorker);
}
function* getMinServiceReportWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.getServiceMinReport(param['payload']);
    yield put(saveServiceMinReportByClientId(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* reactivateTPSaga() {
  yield takeLatest(TYPES.REACTIVATE_TREATMENT_PLAN, reactivateTPWorker);
}
function* reactivateTPWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response = yield clientServices.reactivateTP(param['payload']);
    yield put(loaderAction(false));
    if (response.status) {
      NotificationManager.success('Success')
      yield put(refreshTP(true))
    }
    else {
      NotificationManager.error('Failed')
    }
  }
  catch (err) {
  }
}


export function* getCpFileForTP() {
  yield takeLatest(TYPES.GET_TREATMENT_CP_FILE, cpFileWorker);
}
function* cpFileWorker(param: any) {
  try {
    const response = yield customAxios.post(`${API_ENDPOINTS.GET_CLIENTS_FILE_FOR_TP}`, { treatmentPlanId: param['payload'] });
    if (response.status) {
      yield put(saveClientFileForTreatmentPlan(response))
    }
  }
  catch (err) {
  }
}


export function* getFileUrlSaga() {
  yield takeLatest(TYPES.GET_URL_TO_SAVE_TO_TO_CP, urlWorker);
}
function* urlWorker(param: any) {
  try {
    const params: UploadTreatmentPlanToCpModel = param['payload']
    const formData = new FormData();
    formData.append('treatmentPlanId', params?.treatmentPlanId?.toString() ?? '');
    formData.append('clientID', params.clientID)
    formData.append('fileName', params?.fileName ?? '')
    const response: GenericResponse = yield customAxiosMultipart.post(`${API_ENDPOINTS.GET_S3_URL_TO_UPLOAD_TREATMENT_PLAN}`, formData,);
    if (response.status) {
      yield put(saveDocumentToCp({ ...params, printedTreatmentPlanId: response?.resultData?.printedTreatmentPlanId, uploadUrl: response?.resultData?.presignedUrl }))
    }
  }
  catch (err) {
  }
}

export function* uploadTreatmentSaga() {
  yield takeLatest(TYPES.SAVE_TO_TO_CP, uploadWorker);
}
function* uploadWorker(param: any) {
  try {
    const params: UploadTreatmentPlanToCpModel = param['payload']
    const response: GenericResponse = yield axios.put(params?.uploadUrl ?? '', params.data, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
    if (response.status) {
      NotificationManager.success("File has been saved successfully in the client file manager.");
      yield put(updateSaveRecordDocumentToCp({ ...params, isActive: true }))
    }
  }
  catch (err) {
  }
}

export function* updateRecordFileCreatedSaga() {
  yield takeLatest(TYPES.UPDATE_SAVE_TO_TO_CP, updateWorker);
}
function* updateWorker(param: any) {
  try {
    const params: UploadTreatmentPlanToCpModel = param['payload']
    const response = yield customAxios.delete(`${API_ENDPOINTS.UPDATE_FILE_CREATED_FOR_TP}?id=${params?.printedTreatmentPlanId}&isActive=${params?.isActive}`)
    if (response.status) {
      yield put(saveUploadFileFinalResponse(response))
      if (!params.isActive) {
        NotificationManager.success('Treatment Plan Printed file deleted successfully');
        yield put(getTpPrintedFiles(params?.clientID))
      }
    }
  }
  catch (err) {
  }
}

export function* getPrintedFilesSaga() {
  yield takeLatest(TYPES.GET_TP_PRINTED_FILES, getPrintedFilesWorker);
}

function* getPrintedFilesWorker(param: any) {
  try {
    const response = yield customAxios.post(`${API_ENDPOINTS.GET_CLIENTS_FILES_FOR_TP}`, { clientId: param['payload'] });
    if (response && response.status) {
      yield put(saveTpPrintedFiles(response))
    }
  } catch (err) {
  }
}

export function* searchTpFileSaga() {
  yield takeLatest(TYPES.SEARCH_TP_PRINTED_FILES, searchWorker);
}

function* searchWorker(param: any) {
  try {
    const response = yield customAxios.post(`${API_ENDPOINTS.GET_CLIENTS_FILES_FOR_TP}`, param['payload'] );
    if (response && response.status) {
      yield put(saveTpPrintedFiles(response))
    }
  } catch (err) {
  }
}


export function* getFileForSelectedTreatmentPlanSaga() {
  yield takeLatest(TYPES.GET_TP_S3URL_TO_DOWNLOAD, printWorker);
}

function* printWorker(param: any) {
  try {
    yield put(loaderAction(true))
    const response = yield customAxios.get(`${API_ENDPOINTS.GET_CLINIC_S3_PRINTED_TP_URL_BY_ID}${param['payload']}`);
    if (response && response.status) {
      const s3Url = response?.resultData?.url;
      const s3Response = yield fetch(s3Url);
      const zipBlob = yield s3Response.blob();
      let retry = 1;
      let maxRetry = 3;
      const url = API_ENDPOINTS.PUPPETEER_BASE_URL + '/generate-pdf/treatmentplan-document';
      while (retry <= maxRetry) {
        const response = yield fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/octet-stream",
          },
          body: zipBlob,
        });

        if (!response.ok) {
          if (retry == maxRetry) {
            renderErrors(
              `Error: ${response?.statusText || response?.status}`
            );
            break;
          }
        } else {
          const blob = yield response.blob();
          saveAs(blob, "TreatmentPlan.pdf");
          yield put(loaderAction(false))
          break;
        }
        retry++;
      }

    }
  } catch (err) {
    yield put(loaderAction(false))
  }
}

export function* getSexualOrientationSaga() {
  yield takeLatest(TYPES.GET_SEXUAL_ORIENTATION, getSexualOrientationWorker);
}
function* getSexualOrientationWorker() {
  try {
    yield put(loaderAction(true));
    const response: sexualOrientationResponse = yield clientServices.getSexualOrientation();
    yield put(saveSexualOrientation(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getGenderIdenditySaga() {
  yield takeLatest(TYPES.GET_GENDER_IDENTITY, getGenderIdendityWorker);
}
function* getGenderIdendityWorker() {
  try {
    yield put(loaderAction(true));
    const response: genderIdendityResponse = yield clientServices.getGenderIdentity();
    yield put(saveGenderIdentity(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getMaritalStatusSaga() {
  yield takeLatest(TYPES.GET_MARITAL_STATUS, getMaritalStatusWorker);
}
function* getMaritalStatusWorker() {
  try {
    yield put(loaderAction(true));
    const response: maritalStatusResponse = yield clientServices.getMaritalStatus();
    yield put(saveMaritalStatus(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
  }
}

export function* getClientMedicalHistorySaga() {
  yield takeLatest(TYPES.GET_CLIENT_MEDICAL_HISTORY, getClientMedicalHistoryWorker);
}
function* getClientMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:ClientMedicalHistoryResponseModal = yield clientServices.getClientMedicalHistory(param['payload']);
    yield put(saveClientMedicalHistory(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* insertClientMedicalHistorySaga() {
  yield takeLatest(TYPES.INSERT_CLIENT_MEDICAL_HISTORY, insertClientMedicalHistoryWorker);
}

function* insertClientMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:GenericResponse = yield clientServices.insertClientMedicalHistory(param['payload']);
    const clientId = param['payload']?.getCallClientId;
    const data ={
      getCallClientId:clientId ,
      isActive:true
    }
    if (response && response.resultData) {
      NotificationManager.success("Client medical history added successfully");
      yield put(getClientMedicalHistory(data))
      yield put(loaderAction(false));
    }
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* updateClientMedicalHistorySaga() {
  yield takeLatest(TYPES.UPDATE_CLIENT_MEDICAL_HISTORY, updateClientMedicalHistoryWorker);
}

function* updateClientMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:GenericResponse = yield clientServices.updateClientMedicalHistor(param['payload']);
    const clientId = param['payload']?.getCallClientId;
    const data ={
      getCallClientId:clientId ,
      isActive:true
    }
    if (response && response?.status) {
      NotificationManager.success("Client medical history updated successfully");
      yield put(getClientMedicalHistory(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* deleteClientMedicalHistorySaga() {
  yield takeLatest(TYPES.DELETE_CLIENT_MEDICAL_HISTORY, deleteClientMedicalHistoryWorker);
}

function* deleteClientMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:GenericResponse = yield clientServices.deleteClientMedicalHistory(param['payload']);
    if (response && response?.status) {
     NotificationManager.success("Client medical history deleted successfully");
    const clientId = param['payload']?.getCallClientId;
    const data ={
      getCallClientId:clientId ,
      isActive:true
    }
    yield put(getClientMedicalHistory(data))
   }

   yield put(loaderAction(false));
    

  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* getFamilyMedicalHistorySaga() {
  yield takeLatest(TYPES.GET_FAMILY_MEDICAL_HISTORY, getFamilyMedicalHistoryWorker);
}
function* getFamilyMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:FamilyMedicalHistoryResponseModal = yield clientServices.getFamilyMedicalHistory(param['payload']);
    yield put(saveFamilyMedicalHistory(response?.resultData));
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* insertFamilyMedicalHistorySaga() {
  yield takeLatest(TYPES.INSERT_FAMILY_MEDICAL_HISTORY, insertFamilyMedicalHistoryWorker);
}

function* insertFamilyMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:GenericResponse = yield clientServices.insertFamilyMedicalHistory(param['payload']);
    const clientId = param['payload']?.getCallClientId;  
    const data = {
      getCallClientId:clientId,
      isActive:true
    }
    if (response && response.resultData) {
      NotificationManager.success("Client family medical History added successfully");
      yield put(getFamilyMedicalHistory(data))
      yield put(loaderAction(false));
    }
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* updateFamilyMedicalHistorySaga() {
  yield takeLatest(TYPES.UPDATE_FAMILY_MEDICAL_HISTORY, updateFamilyMedicalHistoryWorker);
}

function* updateFamilyMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:GenericResponse = yield clientServices.updateFamilyMedicalHistory(param['payload']);
    const clientId = param['payload']?.getCallClientId;  
    const data = {
      getCallClientId:clientId,
      isActive:true
    }
    if (response && response?.status) {
      NotificationManager.success("Client family medical History updated successfully");
      yield put(getFamilyMedicalHistory(data))
    }
    yield put(loaderAction(false));
  }
  catch (err) {
    yield put(loaderAction(false));
  }
}

export function* deleteFamilyMedicalHistorySaga() {
  yield takeLatest(TYPES.DELETE_FAMILY_MEDICAL_HISTORY, deleteFamilyMedicalHistoryWorker);
}

function* deleteFamilyMedicalHistoryWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response:GenericResponse = yield clientServices.deleteFamilyMedicalHistory(param['payload']);
    
    if (response && response?.status) {
      const clientId = param['payload']?.getCallClientId;  
      const data = {
      getCallClientId:clientId,
      isActive:true
    }
    yield put(loaderAction(false));
    yield put(getFamilyMedicalHistory(data))
    NotificationManager.success("Client family medical History deleted successfully");
    }

  }
  catch (err) {
    yield put(loaderAction(false));
  }
}
