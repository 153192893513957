import React, { ComponentState, useEffect, useState } from "react";
import { getter } from "@progress/kendo-react-common";
import addIcon from "../../../assets/images/add.png";
import { useDispatch, useSelector } from "react-redux";
import { deleteAuthSet, getAuthSetList } from "src/redux/actions";
import { AuthSets } from "src/dataModels/authSetModels";
import { Grid, GridColumn, GridNoRecords, getSelectedState } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import DeleteDialogModal from "src/control-components/custom-delete-dialog-box/delete-dialog";
import AddEditAuthSet from "./add-edit-auth-sets";
import { orderBy } from "@progress/kendo-data-query";
import { useNavigate } from "react-router";
import { Input } from "@progress/kendo-react-inputs";
import searchIcon from "../../../assets/images/search.png";

import editPencil from "../../../assets/images/pencil-edit-02.png";
import trashIcon from "../../../assets/images/trash2.png";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const ClinicAuthSets = ({ setClientCompliance }) => {
    const navigate = useNavigate();

    const state: ComponentState = useSelector(states => {
        return states;

    });
    const [isSetFetchedn, setIsSetFetched] = useState(false)
    const [authSetList, setAuthSetList] = useState<AuthSets[]>([])
    const { listAuthSets } = state['ClinicsReducer'];
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [sort, setSort] = useState<any>([]);
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState<number>()
    const [isOpenDeleteSet, setIsOpenDeleteSet] = useState(false)
    const [isOpenAddNewSet, setIsOpenAddNewSet] = useState(false)
    const [selectedState, setSelectedState] = React.useState({});
    const [selectedSet, setSelectedSet] = React.useState<AuthSets>();
    const [searchApiQuery, setsearchApiQuery] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (!isSetFetchedn) {
            dispatch(getAuthSetList())
        }
    }, [isSetFetchedn])


    useEffect(() => {
        if (listAuthSets) {
            setAuthSetList(listAuthSets)
            setsearchApiQuery(listAuthSets)
        }
    }, [listAuthSets])

    const handleAddNewSet = () => {
        setSelectedItem(0)
        setSelectedSet(undefined);
        setIsOpenAddNewSet(true)
    }

    const handleDeleteSets = (id: number) => {
        setIsOpenDeleteSet(!isOpenDeleteSet)
        setSelectedItem(id)
    }

    const handleEditSets = (set: AuthSets) => {
        setSelectedSet(set);
        setIsOpenAddNewSet(true)
    }

    const pageChange = (event) => {
        let skip = event.page.skip;
        let take = event.page.take;
        setPage(skip);
        setPageSize(take)
    };

    const handleFilter = (e: any) => {
        if (e.target.value === "") {
            setAuthSetList(searchApiQuery);
        } else {
            const filterResult = searchApiQuery?.filter(
                (item: any) =>
                    (item?.setName && item?.setName.toLowerCase().includes(e.target.value.toLowerCase()))
            );
            setAuthSetList(filterResult);
        }
        setSearchQuery(e.target.value);
    };

    return (
        <>
            <div className="col-md-12 col-lg-12">
                <button
                    type="button"
                    value="BACK"
                    className="border-0 bg-transparent arrow-rotate p-0 m-0"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <i className="fa-solid fa-arrow-left mr-2"></i>
                </button>
                <h4 className="address-title text-grey">
                    <span className="f-24">Authorization Templates</span>
                </h4>
                <div className="d-flex justify-content-between align-items-center">

                    <div className="content-search-filter">
                        <img src={searchIcon} alt="" className="search-icon" />
                        <Input
                            className="icon-searchinput"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => handleFilter(e)}
                        />
                    </div>
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target="#editform"
                        className="submitButon"
                        onClick={handleAddNewSet}
                    >
                        <img src={addIcon} alt="" className="mr-2 add-img" />
                        Add New Template
                    </button>
                </div>


                <div className="grid-table blueThemeTable  filter-grid w-100">
                    <div className=" mt-3">
                        {authSetList &&
                            <Grid
                                data={orderBy(authSetList, sort).map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)], })).slice(page, pageSize + page)}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                skip={page}
                                take={pageSize}
                                total={authSetList ? authSetList?.length : 0}
                                onPageChange={pageChange}
                                className="pagination-row-cus"
                                pageable={{ pageSizes: [10, 20, 30] }}
                                sort={sort}
                                sortable={true}
                                onSortChange={(e) => { setSort(e.sort); }}>
                                <GridNoRecords ></GridNoRecords>

                                <GridColumn
                                    className="cursor-default"
                                    field="setName"
                                    title="Name"
                                    width="200px"
                                />



                                <GridColumn
                                    title="Action"
                                    width="150px"
                                    filterable={false}
                                    cell={(props) => {
                                        let id = props.dataItem?.id;
                                        return (
                                            <td>
                                                <div className="row-3">
                                                    <div
                                                        className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base" style={{background:"#ffebee"}}
                                                        onClick={() => handleDeleteSets(id)}
                                                    >
                                                        <div className="k-chip-content">
                                                            <Tooltip anchorElement="target" position="top">
                                                                {/* <i className="fa fa-trash" title="Delete"></i> */}
                                                                <img src={trashIcon} alt="trash icon"   aria-hidden="true"
                                          title="Delete" />
                                                            </Tooltip>
                                                        </div>

                                                    </div>
                                                    <div
                                                        className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base m-2 edit-btn"
                                                        onClick={() => handleEditSets(props.dataItem)}
                                                    >
                                                        <div className="k-chip-content">
                                                            <Tooltip anchorElement="target" position="top">
                                                                {/* <i className="fas fa-edit" title="Edit"></i> */}
                                                                <img
                src={editPencil}
                alt=" edit "
                title="Edit"
                aria-hidden="true"
                style={{ maxWidth: "18px" }}
                className="cursor-pointer"
              />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        );
                                    }}
                                />
                            </Grid>}
                    </div>
                    {isOpenDeleteSet && (
                        <DeleteDialogModal
                            onClose={() => { setIsOpenDeleteSet(false) }}
                            title="Authorization set"
                            message="authorization set"
                            handleDelete={() => {
                                setIsOpenDeleteSet(false)
                                dispatch(deleteAuthSet(selectedItem))
                            }}
                        />
                    )}

                    {
                        isOpenAddNewSet && <AddEditAuthSet
                            preselectedData={selectedSet}
                            onClose={() => { setIsOpenAddNewSet(false) }}
                            callback={setClientCompliance} />
                    }
                </div></div>


        </>
    )
}

export default ClinicAuthSets


